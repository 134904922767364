import { useLocation, Navigate, Outlet } from "react-router-dom";
import { authRoutes, privateRoutes, publicRoutes } from "../../routes/routes";

const RequireAuth = ({ isAuthenticated }) => {
  const location = useLocation();
  const isPublicRoute = () => {
    const isPublic = !!publicRoutes.find(({ path }) => {
      return location.pathname === path;
    });
    return isPublic;
  };

  const isAuthRoute = () =>
    !!authRoutes.find(({ path }) => location.pathname === path);
  const isPrivateRoute = () =>
    !!privateRoutes.find(({ path }) => location.pathname === path);
  if (!isAuthenticated) {
    if (
      isPrivateRoute() ||
      location.pathname === "/" ||
      location.pathname === "/home-member"
    ) {
      return <Navigate to="/home" state={{ from: location }} replace />;
    }
  }
  if (isAuthenticated && isAuthRoute()) {
    return <Navigate to="/home-member" state={{ from: location }} replace />;
  }

  if (
    isAuthenticated &&
    (location.pathname === "/" || location.pathname === "/home")
  ) {
    return <Navigate to="/home-member" state={{ from: location }} replace />;
  }
  return <Outlet />;
};

export default RequireAuth;
