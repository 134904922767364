import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { withLayout } from "./Components/Layouts/rootLayout";
import ErrorPage from "./Pages/errorPage";
import { privateRoutes, publicRoutes } from "./routes/routes";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAuthenticated } from "./redux/authSlice";
import { useEffect } from "react";
import RequireAuth from "./Components/Global/requireAuth";
import { changeLang, selectLangState } from "./redux/configurationSlice";
import i18n from "./i18";
import "./App.css";
import Recaptcha from "./Pages/Recaptcha";
import NotFound from "./Pages/NotFound";
import { MoengageService } from "./services/moengage";

const App = () => {
  const { lang, textSize, colorClass } = useSelector(selectLangState);
  const dispatch = useDispatch();
  useEffect(() => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const params = new URLSearchParams(url.search);
    const language = params.get("language");
    if (language) {
      const defaultLang = language?.startsWith("ar") ? "ar" : "en";
      i18n.changeLanguage(defaultLang);
      document.documentElement.lang = defaultLang;
      document.documentElement.dir = language?.startsWith("ar") ? "rtl" : "ltr";
      dispatch(changeLang({ lang: defaultLang }));
    } else if (lang) {
      const defaultLang = lang?.startsWith("ar") ? "ar" : "en";
      i18n.changeLanguage(defaultLang);
      document.documentElement.lang = defaultLang;
      document.documentElement.dir = lang?.startsWith("ar") ? "rtl" : "ltr";
    } else {
      const browserLang = "en";
      const defaultLang = browserLang?.startsWith("ar") ? "ar" : "en";
      i18n.changeLanguage(defaultLang);
      document.documentElement.lang = defaultLang;
      document.documentElement.dir = browserLang?.startsWith("ar")
        ? "rtl"
        : "ltr";
      dispatch(changeLang({ lang: defaultLang }));
    }

    return () => {
      document.documentElement.lang = "en";
      document.documentElement.dir = "ltr";
    };
  }, [lang, window.location.href]);
  // useEffect(() => {
  //   if (lang) {
  //     const defaultLang = lang?.startsWith("ar") ? "ar" : "en";
  //     i18n.changeLanguage(defaultLang);
  //     document.documentElement.lang = defaultLang;
  //     document.documentElement.dir = lang?.startsWith("ar") ? "rtl" : "ltr";
  //   } else {
  //     const browserLang = navigator.language || navigator.userLanguage;
  //     const defaultLang = browserLang?.startsWith("ar") ? "ar" : "en";
  //     i18n.changeLanguage(defaultLang);
  //     document.documentElement.lang = defaultLang;
  //     document.documentElement.dir = browserLang?.startsWith("ar")
  //       ? "rtl"
  //       : "ltr";
  //   }
  //   return () => {
  //     document.documentElement.lang = "en";
  //     document.documentElement.dir = "ltr";
  //   };
  // }, [lang]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--font-size-16",
      `${textSize}px`
    );
  }, [textSize]);

  useEffect(() => {
    document.body.className = colorClass ?? "normal";
  }, [colorClass]);

  useEffect(() => {
    MoengageService.init();
  }, []);

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/"
          element={<RequireAuth isAuthenticated={isAuthenticated} />}
          errorElement={<ErrorPage />}
        >
          <Route path="/get-recaptcha-token" element={<Recaptcha />} />

          {/* <Route element={<RootLayout isAuthenticated={isAuthenticated} />}> */}
          {[...privateRoutes, ...publicRoutes].map(
            ({ path, element, isAuthRoute, children, isHeaderFooter }, key) => (
              <Route
                path={path}
                element={withLayout(
                  element,
                  isAuthenticated,
                  isAuthRoute,
                  isHeaderFooter
                )}
                key={key}
              >
                {children &&
                  children.map((child, key) => <Route {...child} key={key} />)}
              </Route>
            )
          )}
          {/* </Route> */}
          <Route path="*" element={withLayout(NotFound, false, true)} />
        </Route>
      </>
    )
  );
  return <RouterProvider router={router} />;
};

export default App;
