import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AuthAPI } from "../apis/authApi";

const initialState = {
  isAuthenticated: false,
  isOnboarding: true,
  token: null,
  role: [],
  userDetails: {},
  permissions: [],
  loading: false,
  error: null,
  email: null,
  logoutState: "idle",
};

const handleLoginData = (state, action) => {
  state.loading = false;
  const { token, user } = action.payload;
  state.isAuthenticated = true;
  state.token = token;
  state.userDetails = user;
  state.error = null;
};

const handleUSerData = (state, action) => {
  const { user } = action.payload;
  state.userDetails = user;
  state.error = null;
};

export const login = createAsyncThunk(
  "auth/login",
  async (data, { rejectWithValue }) => {
    try {
      let res = await AuthAPI.login(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: () => initialState,
    closeOnboarding: (state) => {
      state.isOnboarding = false;
    },
    handleLogin: handleLoginData,
    handleUSer: handleUSerData,
    handleToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, handleLoginData)
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectToken = (state) => state.auth.token;
export const selectRole = (state) => state?.auth?.role;
export const selectPermissions = (state) => state.auth.permissions;
export const selectUserDetails = (state) => state.auth.userDetails;
export const selectLoginLoading = (state) => state.auth.loading;
export const selectUserEmail = (state) => state.auth.email;
export const selectError = (state) => state.auth.error;
export const selectLogoutState = (state) => state.auth.logoutState;
export const selectOnboarding = (state) => state.auth.isOnboarding;

export const {
  resetAuth,
  closeOnboarding,
  handleLogin,
  handleUSer,
  handleToken,
} = authSlice.actions;
export default authSlice.reducer;
