import Slider from "rc-slider";
import { cloneElement } from "react";

const InputSlider = (props) => (
  <Slider
    {...props}
    handleRender={(node, { value = 0 }) => (
      <TooltipWrapper value={value}>{node}</TooltipWrapper>
    )}
  />
);

const TooltipWrapper = ({ children, value }) =>
  cloneElement(children, {}, <div className="value">{value}</div>);

export default InputSlider;

// props: min, max, hanldeChange, reverse, range, defaultValues
