import { apiService } from "./configs/axiosConfig";
const searchURL = process.env.REACT_APP_SEARCH_API;
export const SearchAPI = {
  getTrendsandHistory: async (data) => {
    const response = await apiService({
      url: `/configs`,
      method: "POST",
      data: data,
    });

    return response?.data ?? response;
  },
  search: async (data) => {
    const response = await apiService(
      {
        url: `/search`,
        method: "POST",
        data: data,
      },
      { baseURL: searchURL }
    );
    return response?.data ?? response;
  },
};
