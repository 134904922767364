import Image from "react-bootstrap/Image";

const PageBanner = ({ className, imageUrl, title }) => {
  return (
    <div className={`pageBnr ${className}`}>
      <Image src={imageUrl} alt="Background" fluid className="d-block w-100" />
      <div className="cover d-flex align-items-center justify-content-center ">
        <h1 className="fw-700 font-bukra text-white m-0 text-capitalize">
          {title}
        </h1>
      </div>
    </div>
  );
};

export default PageBanner;
