import PageBanner from "../Components/Global/PageBanner";
import AppDownload from "../Components/Global/AppDownload";
import { Container, Row, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { HomeAPI } from "../apis/homeApi";
import { useSelector } from "react-redux";
import { selectLangState } from "../redux/configurationSlice";
import { useEffect } from "react";
import PageLoader from "../Components/loaders/pageLoader";

const PartnersFacilities = () => {
  const { lang } = useSelector(selectLangState);
  const { t } = useTranslation();
  const { data: featuredMerchants, isLoading } = useQuery({
    queryKey: [
      "featuredMerchants",
      {
        partner_type: "MERCHANT",
      },
    ],
    queryFn: ({ queryKey }) => HomeAPI.getMerchants({ ...queryKey[1] }),
  });

  return (
    <>
      <PageBanner
        imageUrl="/images/bg-pageBnr.png"
        title={`${t("Partner Facilities")}`}
      />
      <div className="contentHolder add prtenerPage">
        <Container>
          <Row className="cardTypes g-4">
            <Col md={6} xs={12}>
              <div className="item">
                <h2 className="text-uppercase fw-700">
                  {t("SILVER")}
                  <span className="d-block fw-300">{t("Partner")}</span>
                </h2>
                <p className="fw-400">
                  {t("Assigned to companies that")}
                  <br /> {t("offer discount from")}
                </p>
                <p className="dis-ratio font-bukra fw-500">20%-39%</p>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="item gold">
                <h2 className="text-uppercase fw-700">
                  {t("Gold")}
                  <span className="d-block fw-300">{t("Partner")}</span>
                </h2>
                <p className="fw-400">
                  {t("Assigned to companies that")}
                  <br /> {t("offer discount from")}
                </p>
                <p className="dis-ratio font-bukra fw-500">40%-80%</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="head">
                <h2 className="fw-700 font-bukra text-black title-lg">
                  {t("Featured Partners")}
                </h2>
                <small className="fw-300 text-black text-info">
                  {t("featuredPartnersText")}
                </small>
              </div>
            </Col>
          </Row>
          {isLoading ? (
            <PageLoader />
          ) : (
            featuredMerchants?.featured_partners_logo_urls?.length > 0 && (
              <Row className="g-4 brandLogos-featured">
                {featuredMerchants?.featured_partners_logo_urls?.map(
                  (logo, index) => (
                    <Col
                      md={3}
                      xs={6}
                      className="d-flex align-items-center justify-content-center"
                      key={index}
                    >
                      <div className="imgHolder d-flex justify-content-center">
                        <Image
                          src={logo}
                          alt="Partner Logo"
                          className="d-block w-50"
                        />
                      </div>
                    </Col>
                  )
                )}
              </Row>
            )
          )}
          {/* <Row className="g-4 brandLogos-featured">
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-1.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-2.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-3.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-4.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-5.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-6.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-7.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-8.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-9.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-10.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-11.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-12.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-13.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-14.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-15.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-16.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-17.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-18.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-19.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
            <Col
              md={3}
              xs={6}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="imgHolder d-flex justify-content-center">
                <Image
                  src="/images/fp-20.png"
                  alt="Partner Logo"
                  className="d-block w-50"
                />
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
      <AppDownload />
    </>
  );
};

export default PartnersFacilities;
