import React from "react";
import { Image } from "react-bootstrap";

const PageLoader = () => {
  return (
    <div className="pageLoader">
      <div className="imgHolder">
        <Image
          src="/images/esaad-loader.png"
          alt="Loader"
          img-fluid
          className="d-block w-100"
        />
        <div className="dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default PageLoader;
