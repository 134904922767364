import { apiService } from "./configs/axiosConfig";

export const AuthAPI = {
  login: async (data) => {
    try {
      const response = await apiService({
        url: `/login`,
        method: "POST",
        data: { ...data },
      });

      return response?.data ?? response;
    } catch (error) {
      throw error;
    }
  },
  uaePassLogin: async (data) => {
    try {
      const response = await apiService({
        url: `/uaepass/login`,
        method: "POST",
        data: { ...data },
      });

      return response?.data ?? response;
    } catch (error) {
      throw error;
    }
  },
  logout: async (data) => {
    try {
      const response = await apiService({
        url: `/logout`,
        method: "POST",
        data,
      });
      return response?.data ?? response;
    } catch (error) {
      throw error;
    }
  },
  changePassword: async (data) => {
    try {
      const response = await apiService({
        url: `/change/password`,
        method: "POST",
        data: { ...data },
      });

      return response?.data ?? response;
    } catch (error) {
      throw error;
    }
  },
  resetPassword: async (data) => {
    try {
      const response = await apiService({
        url: `/reset/password`,
        method: "POST",
        data: { ...data },
      });

      return response?.data ?? response;
    } catch (error) {
      throw error;
    }
  },
  updatePassword: async (data) => {
    try {
      const response = await apiService({
        url: `/update/password`,
        method: "POST",
        data: { ...data },
      });

      return response?.data ?? response;
    } catch (error) {
      throw error;
    }
  },
  getRedemptionsHistory: async (data) => {
    try {
      const response = await apiService({
        url: `/redemption/history`,
        method: "POST",
        data,
      });

      return response?.data ?? response;
    } catch (error) {
      throw error;
    }
  },
  getFavorites: async (data) => {
    try {
      const response = await apiService({
        url: `/outlets`,
        method: "POST",
        data: { ...data },
      });

      return response?.data ?? response;
    } catch (error) {
      throw error;
    }
  },
  addFavorites: async (data) => {
    try {
      const response = await apiService({
        url: `/favourite/add`,
        method: "POST",
        data: { ...data },
      });

      return response?.data ?? response;
    } catch (error) {
      throw error;
    }
  },
  removeFavorites: async (data) => {
    try {
      const response = await apiService({
        url: `/favourite/remove`,
        method: "POST",
        data: { ...data },
      });

      return response?.data ?? response;
    } catch (error) {
      throw error;
    }
  },
  activateMembership: async (data) => {
    const response = await apiService({
      url: `/verify/emirates_id`,
      method: "POST",
      data,
    });

    return response?.data ?? response;
  },
  verifyOtp: async (data) => {
    const response = await apiService({
      url: `/verify/otp`,
      method: "POST",
      data: data,
    });

    return response?.data ?? response;
  },

  verifyEmail: async (data) => {
    const response = await apiService({
      url: `/verify/email`,
      method: "POST",
      data,
    });

    return response?.data ?? response;
  },
  verifyEmailByToken: async (data) => {
    const response = await apiService({
      url: `/verify/user/email`,
      method: "POST",
      data,
    });

    return response?.data ?? response;
  },
  updateUser: async (data) => {
    const response = await apiService({
      url: `/update/user`,
      method: "POST",
      data,
    });

    return response?.data ?? response;
  },
  getUserProfile: async (data) => {
    const response = await apiService({
      url: `/user/profile`,
      method: "POST",
      data,
    });

    return response?.data ?? response;
  },
  sendOtp: async (data) => {
    const response = await apiService({
      url: `/send/otp`,
      method: "POST",
      data,
    });
    return response?.data ?? response;
  },
  sendForgotEmail: async (data) => {
    const response = await apiService({
      url: `/send/forget/password/email`,
      method: "POST",
      data,
    });
    return response?.data ?? response;
  },
  updateUserProfile: async (data) => {
    const response = await apiService({
      url: `/upload/profile/image`,
      method: "POST",
      data,
    });
    return response?.data ?? response;
  },
  registerUser: async (data) => {
    const response = await apiService({
      url: `/register/user`,
      method: "POST",
      data,
    });
    return response?.data ?? response;
  },
  getDevices: async (data) => {
    const response = await apiService({
      url: `/linked/devices`,
      method: "POST",
      data,
    });
    return response?.data ?? response;
  },
  removeDevice: async (data) => {
    const response = await apiService({
      url: `/remove/linked/device`,
      method: "POST",
      data,
    });
    return response?.data ?? response;
  },
  deleteAccount: async (data) => {
    const response = await apiService({
      url: `/delete/user/account`,
      method: "POST",
      data,
    });
    return response?.data ?? response;
  },
  withdrawAccount: async (data) => {
    const response = await apiService({
      url: `/delete/user/withdraw`,
      method: "POST",
      data,
    });
    return response?.data ?? response;
  },
};
