import { apiService } from "./configs/axiosConfig";

export const NEWSAPI = {
  getNews: async (data) => {
    try {
      const response = await apiService({
        url: "/news/all",
        method: "POST",
        data: data,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getNewsDetail: async (data) => {
    try {
      const response = await apiService({
        url: "/news",
        method: "POST",
        data: data,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getSuccessStories: async (data) => {
    try {
      const response = await apiService({
        url: "/story/all",
        method: "POST",
        data: data,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getSuccessStory: async (data) => {
    try {
      const response = await apiService({
        url: "/story",
        method: "POST",
        data: data,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
