import { createSlice } from "@reduxjs/toolkit";
import i18n from "../i18";

const initialState = {
  lang: null,
  colorClass: "normal",
  textSize: 16,
  readerLang: "en",
};

const handleLanguage = (state, action) => {
  const { lang } = action.payload;
  i18n.changeLanguage(lang);
  state.lang = lang;
  return state;
};

const changeColor = (state, action) => {
  state.colorClass = action.payload;
};

const changeText = (state, action) => {
  state.textSize = action.payload;
};

const changeReaderLanguage = (state, action) => {
  state.readerLang = action.payload;
};

const configurationSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetConfiguration: () => initialState,
    changeLang: handleLanguage,
    changeColorClass: changeColor,
    changeTextSize: changeText,
    changeReaderLang: changeReaderLanguage,
  },
});

export const selectLangState = (state) => state.configuration;
export const selectReaderLang = (state) => state.configuration.readerLang;

export const {
  resetConfiguration,
  changeLang,
  changeColorClass,
  changeTextSize,
  changeReaderLang,
} = configurationSlice.actions;
export default configurationSlice.reducer;
