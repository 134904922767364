import { Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';

const CardNotification = ({ to, className, imageUrl, offerTitle, text, discount }) => {
  return (
    <div className={`hrItemCard ${className}`}>
        <div className='src-Card hrItem d-flex'>
            <div className='imgHolder me-3'>
                <Link to={to}>
                    <Image src={imageUrl} alt="image" className='img-fluid d-block w-100' />
                </Link>
            </div>
            <div className='text'>
                <div className='head d-flex align-items-start justify-content-between '>
                    <h3 className='font-bukra'><Link to={to} className='primary-color text-decoration-none'>{offerTitle}</Link></h3>
                    <Stack className='position-static align-items-start' direction="horizontal" gap={2}>
                        <Badge pill bg="primary">
                            {discount}
                        </Badge>
                    </Stack>
                </div>
                <p>{text}</p>
            </div>
        </div>
    </div>
  )
}

export default CardNotification