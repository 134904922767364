import React from "react";
import { Button } from "react-bootstrap";

const ButtonWithLoading = ({
  children,
  text,
  variant = "primary",
  className = "w-100",
  isLoading = false,
  ...props
}) => {
  return (
    <Button
      variant={variant}
      type="submit"
      className={className}
      disabled={isLoading}
      {...props}
    >
      {!isLoading ? (
        <>
          {text} {children}
        </>
      ) : (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      )}
    </Button>
  );
};

export default ButtonWithLoading;
