import { authRoutes } from "./routes/routes";

export const navigations = [
  { title: "Home", link: "/home" },
  { title: "About Us", link: "/about" },
  {
    title: "Media Center",
    link: "#",
    dropdownItems: [
      { title: "News", link: "/media-center/news" },
      { title: "Success Stories", link: "/media-center/success-stories" },
    ],
  },
  { title: "Partner Facilities", link: "/partner-facilities" },
  { title: "Inquiries", link: "/contact" },
  { title: "Register Company", link: "/register-company" },
];
export const countryOptions = [
  {
    value: "uae",
    label: "United Arab Emirates",
  },
];
export const categoryOptions = [
  { value: "restaurantandentertainment", label: "Restaurant & Entertainment" },
  { value: "entertainment", label: "Entertainment" },
  { value: "restaurant", label: "Restaurant" },
];
export const cityOptions = [
  {
    value: "abu-dhabi",
    label: "Abu Dhabi",
  },
  {
    value: "dubai",
    label: "Dubai",
  },
  {
    value: "sharjah",
    label: "Sharjah",
  },
  {
    value: "ajman",
    label: "Ajman",
  },
  {
    value: "umm-al-quwain",
    label: "Umm Al-Quwain",
  },
  {
    value: "fujairah",
    label: "Fujairah",
  },
  {
    value: "ras-al-khaimah",
    label: "Ras Al Khaimah",
  },
];
export const areaOptions = [
  { value: "dubai", label: "Dubai" },
  { value: "abu_dhabi", label: "Abu Dhabi" },
  { value: "sharjah", label: "Sharjah" },
];
export const mallOptions = [
  { value: "dubai_mall", label: "The Dubai Mall" },
  { value: "mall_of_emirates", label: "Mall of the Emirates" },
  { value: "ibn_battuta_mall", label: "Ibn Battuta Mall" },
  { value: "marina_mall_abu_dhabi", label: "Marina Mall Abu Dhabi" },
  { value: "yasmall", label: "Yas Mall" },
  { value: "city_centre_deira", label: "City Centre Deira" },
  { value: "city_centre_mirdif", label: "City Centre Mirdif" },
];

export const defaultCompanyValues = {
  companyNameEn: "",
  companyNameAr: "",
  descriptionEn: "",
  descriptionAr: "",
  addressEn: "",
  addressAr: "",
  ownerNameEn: "",
  ownerNameAr: "",
  email: "",
  mobile: "",
  landlineNumber: "",
  category: "",
  country: "",
  emirate: "",
  websiteURL: "",
  facebookURL: "",
  instagramURL: "",
  xURL: "",
  image: "",
  license: "",
  passport: "",
  hasBranches: false,
  branches: [],
};

export const defaultBranchValues = {
  nameEn: "",
  nameAr: "",
  addressEn: "",
  addressAr: "",
  longitude: "",
  latitude: "",
  country: "",
  emirate: "",
  city: "",
  mall: "",
  landlineNumber: "",
  mobile: "",
  managerNameEn: "",
  managerNameAr: "",
  managerEmail: "",
  managerMobile: "",
  isPrimary: false,
  isEdit: false,
};

export const publicNavigations = [...navigations];

export const privateNavigations = navigations.toSpliced(
  0,
  1,
  { title: "Home", link: "/home-member" },

  {
    title: "Categories",
    link: "#",
    dropdownItems: [],
  }
);

export const categories = [
  {
    title: "Automotive",
    alt: "Automotive",
    imageSrc: "/images/cat-automotaive.svg",
    to: "",
    className: "automotive",
  },
  {
    title: "Education",
    alt: "Education",
    imageSrc: "/images/cat-education.svg",
    to: "",
    className: "education",
  },
  {
    title: "Family",
    alt: "Family",
    imageSrc: "/images/cat-family.svg",
    to: "",
    className: "family",
  },
  {
    title: "Health",
    alt: "Health",
    imageSrc: "/images/cat-health.svg",
    to: "",
    className: "health",
  },
  {
    title: "Malls & Retail Stores",
    alt: "Mall",
    imageSrc: "/images/cat-malls-store.svg",
    to: "",
    className: "mall",
  },
  {
    title: "Online Shopping",
    alt: "Online Shopping",
    imageSrc: "/images/cat-shopping.svg",
    to: "",
    className: "shopping",
  },
  {
    title: "Public",
    alt: "Public",
    imageSrc: "/images/cat-public.svg",
    to: "",
    className: "public",
  },
  {
    title: "Residential",
    alt: "Residential",
    imageSrc: "/images/cat-residential.svg",
    to: "",
    className: "residential",
  },
  {
    title: "Restaurant & Entertainme",
    alt: "Restaurent",
    imageSrc: "/images/cat-restaurent.svg",
    to: "",
    className: "restaurent",
  },
  {
    title: "Travel and Tourism",
    alt: "Restaurent",
    imageSrc: "/images/cat-travel.svg",
    to: "",
    className: "travel",
  },
];

export const pagesWithDarkBackground = [
  ...authRoutes.map((el) => el.path),
  "/home",
];

export const HEADER_LINKS = {
  CATEGORIES: "Categories",
  MEDIA_CENTER: "Media Center",
};

export const ROUTES_TO_HIDE_MENU = [
  "/faqs",
  "/privacy-policy",
  "/about",
  "/partner-facilities",
  "/terms-and-condition",
  "/media-center/success-stories",
  "/how-it-works/en",
  "/how-it-works/ar",
];
