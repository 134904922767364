import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import OfferCard from "../Components/Global/OfferCard";
import Image from "react-bootstrap/Image";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { selectLangState } from "../redux/configurationSlice";
import { useSelector } from "react-redux";

import { Navigation, Pagination, EffectFade } from "swiper/modules";
import { HomeAPI } from "../apis/homeApi";
import { Fragment, useEffect, useState } from "react";
import PageLoader from "../Components/loaders/pageLoader";

const Offers = () => {
  const { name } = useParams();
  const { lang } = useSelector(selectLangState);
  const [location, setLocation] = useState({ lat: null, lng: null });

  const { t } = useTranslation();
  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      "section/items",
      {
        company: "ESD",
        location_id: 0,
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
        lat: location?.lat,
        lng: location?.lng,
        section_identifier: name,
      },
    ],
    queryFn: ({ queryKey }) => HomeAPI.getSectionItems({ ...queryKey[1] }),
    enabled: location?.lat != null && location?.lng != null,
  });
  useEffect(() => {
    // Request the user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Extract latitude and longitude
          const { latitude, longitude } = position.coords;

          // Send location to backend
          setLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting location: ", error);
        }
      );
    }
  }, []);

  if (isLoading) return <PageLoader />;

  return (
    <>
      <div className="contentHolder">
        <Container>
          <h1 className="title-lg page-head primary-color fw-700 font-bukra">
            {data?.sections?.[0]?.title}
          </h1>
          <Row>
            {data?.sections?.[0]?.section_items?.length ? (
              data?.sections?.[0]?.section_items?.map((offer, index) => (
                <Fragment key={index}>
                  <Col xs={12} md={6} xl={3} className="mb-4">
                    <OfferCard
                      to={`/merchant-detail/${offer?.merchant_id}`}
                      imageUrl={offer?.image_url}
                      offerTitle={offer?.title}
                      address={offer?.address}
                      category={offer?.outlet_name}
                      outlet={offer?.outlet_name}
                      offerType={offer?.type}
                      discount={offer?.discount}
                      distance={offer?.distance}
                      rating={offer?.avg_rating}
                      tags={offer?.tags}
                      isFavorite={offer?.is_favourite}
                      offer={offer}
                      valid_till={offer?.valid_till_date}
                      refetch={refetch}
                    />
                  </Col>
                  {/* <div className="offers-category-row">
                  <Swiper
                    key={lang?.startsWith("ar") ? "rtl" : "ltr"}
                    dir={lang?.startsWith("ar") ? "rtl" : "ltr"}
                    navigation={{
                      prevEl: ".swiper-automotive-prev",
                      nextEl: ".swiper-automotive-next",
                    }}
                    modules={[Navigation]}
                    className="offer-swiper add"
                    breakpoints={{
                      320: {
                        slidesPerView: 1,
                        spaceBetween: 12,
                      },
                      768: {
                        slidesPerView: 2,
                        spaceBetween: 24,
                      },
                      1200: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                      },
                    }}
                  >
                    {category?.merchants?.map((offer, key) => (
                      <SwiperSlide key={offer}>
                       
                      </SwiperSlide>
                    ))}
                    <div className="swiper-button-prev swiper-automotive-prev"></div>
                    <div className="swiper-button-next swiper-automotive-next"></div>
                  </Swiper>
                </div> */}
                </Fragment>
              ))
            ) : (
              <NoDataComponent />
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

const NoDataComponent = () => {
  const { t } = useTranslation();
  return (
    <div
      id="error-page"
      style={{ height: "60vh" }}
      className=" d-flex flex-column align-items-center justify-content-center"
    >
      <div className="row p-2 m-2  text-black  rounded-2">
        <h1>{t("Oops")}!</h1>
      </div>
      <div className="row pt-3 mt-2 mb-3 bg-warning shadow rounded-2">
        <p>
          <i>{t("No Data found!")}</i>
        </p>
      </div>
    </div>
  );
};

export default Offers;
