import React from "react";
import Badge from "react-bootstrap/Badge";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { selectLangState } from "../../redux/configurationSlice";
import { useSelector } from "react-redux";

const BranchTab = ({ branch, onEditClick, onDelete, index }) => {
  const { lang } = useSelector(selectLangState);

  const { nameEn, nameAr, addressEn, addressAr, isPrimary } = branch;

  const { t } = useTranslation();
  return (
    <div className="brhTab">
      <Row className="align-items-center">
        <Col
          xl={4}
          lg={4}
          xs={12}
          className="d-flex justify-content-between align-items-center"
        >
          <span className="title d-block singleLine">
            {lang?.startsWith("ar") ? nameAr : nameEn}
          </span>
          <div className="icons d-flex d-lg-none align-items-center ms-auto">
            {isPrimary && (
              <span className="icon">
                <Badge pill bg="primary">
                  {t("Primary")}
                </Badge>
              </span>
            )}
            <span
              className="icon"
              onClick={() => onEditClick({ ...branch, isEdit: true })}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_3853_74203)">
                  <path
                    d="M19 0H5C2.243 0 0 2.243 0 5V19C0 21.757 2.243 24 5 24H19C21.757 24 24 21.757 24 19V5C24 2.243 21.757 0 19 0ZM22 19C22 20.654 20.654 22 19 22H5C3.346 22 2 20.654 2 19V5C2 3.346 3.346 2 5 2H19C20.654 2 22 3.346 22 5V19ZM13.879 6.379L7.172 13.086C6.417 13.841 6 14.846 6 15.914V17.5C6 18.053 6.448 18.5 7 18.5H8.586C9.654 18.5 10.659 18.084 11.414 17.328L18.121 10.621C19.291 9.451 19.291 7.549 18.121 6.379C16.987 5.246 15.012 5.246 13.879 6.379ZM10 15.914C9.627 16.286 9.112 16.5 8.586 16.5H8V15.914C8 15.38 8.208 14.878 8.586 14.5L12.836 10.25L14.25 11.664L10 15.914ZM16.707 9.207L15.664 10.25L14.25 8.836L15.293 7.793C15.67 7.414 16.329 7.414 16.707 7.793C17.097 8.183 17.097 8.817 16.707 9.207Z"
                    fill="#737373"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3853_74203">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <span
              className="icon"
              onClick={(e) => {
                e.preventDefault();
                onDelete(index);
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 4H17V2C17 1.46957 16.7893 0.960859 16.4142 0.585786C16.0391 0.210714 15.5304 0 15 0L9 0C8.46957 0 7.96086 0.210714 7.58579 0.585786C7.21071 0.960859 7 1.46957 7 2V4H2V6H4V21C4 21.7956 4.31607 22.5587 4.87868 23.1213C5.44129 23.6839 6.20435 24 7 24H17C17.7957 24 18.5587 23.6839 19.1213 23.1213C19.6839 22.5587 20 21.7956 20 21V6H22V4ZM9 2H15V4H9V2ZM18 21C18 21.2652 17.8946 21.5196 17.7071 21.7071C17.5196 21.8946 17.2652 22 17 22H7C6.73478 22 6.48043 21.8946 6.29289 21.7071C6.10536 21.5196 6 21.2652 6 21V6H18V21Z"
                  fill="#737373"
                />
                <path d="M11 10H9V18H11V10Z" fill="#737373" />
                <path d="M15 10H13V18H15V10Z" fill="#737373" />
              </svg>
            </span>
          </div>
        </Col>
        <Col xl={5} lg={4} xs={12}>
          <div className="addressCol d-flex align-items-center">
            <span className="icon me-2 ">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_3853_74228)">
                  <path
                    d="M8.00261 4C7.47519 4 6.95962 4.1564 6.52109 4.44941C6.08255 4.74243 5.74076 5.15891 5.53893 5.64618C5.33709 6.13345 5.28428 6.66962 5.38718 7.18691C5.49007 7.70419 5.74405 8.17934 6.11699 8.55228C6.48993 8.92522 6.96508 9.1792 7.48236 9.28209C7.99965 9.38499 8.53583 9.33218 9.02309 9.13034C9.51036 8.92851 9.92684 8.58672 10.2199 8.14819C10.5129 7.70966 10.6693 7.19408 10.6693 6.66667C10.6693 5.95942 10.3883 5.28115 9.88822 4.78105C9.38813 4.28095 8.70985 4 8.00261 4ZM8.00261 8C7.7389 8 7.48111 7.9218 7.26185 7.77529C7.04258 7.62878 6.87168 7.42055 6.77077 7.17691C6.66985 6.93328 6.64344 6.66519 6.69489 6.40655C6.74634 6.1479 6.87333 5.91033 7.0598 5.72386C7.24627 5.53739 7.48384 5.4104 7.74248 5.35895C8.00113 5.30751 8.26921 5.33391 8.51285 5.43483C8.75648 5.53574 8.96472 5.70664 9.11123 5.92591C9.25774 6.14517 9.33594 6.40296 9.33594 6.66667C9.33594 7.02029 9.19546 7.35943 8.94541 7.60947C8.69537 7.85952 8.35623 8 8.00261 8Z"
                    fill="#008755"
                  />
                  <path
                    d="M8.00021 16.0001C7.43884 16.003 6.88495 15.8713 6.38492 15.6162C5.88489 15.361 5.45327 14.9897 5.12621 14.5335C2.58554 11.0288 1.29688 8.39412 1.29688 6.70212C1.29688 4.92428 2.00312 3.21926 3.26024 1.96214C4.51735 0.705022 6.22237 -0.0012207 8.00021 -0.0012207C9.77804 -0.0012207 11.4831 0.705022 12.7402 1.96214C13.9973 3.21926 14.7035 4.92428 14.7035 6.70212C14.7035 8.39412 13.4149 11.0288 10.8742 14.5335C10.5471 14.9897 10.1155 15.361 9.6155 15.6162C9.11547 15.8713 8.56158 16.003 8.00021 16.0001ZM8.00021 1.45411C6.60849 1.4557 5.27422 2.00926 4.29012 2.99336C3.30603 3.97746 2.75246 5.31173 2.75087 6.70345C2.75087 8.04345 4.01287 10.5215 6.30354 13.6808C6.498 13.9486 6.75312 14.1666 7.04802 14.317C7.34291 14.4673 7.66921 14.5456 8.00021 14.5456C8.33121 14.5456 8.6575 14.4673 8.9524 14.317C9.24729 14.1666 9.50241 13.9486 9.69687 13.6808C11.9875 10.5215 13.2495 8.04345 13.2495 6.70345C13.248 5.31173 12.6944 3.97746 11.7103 2.99336C10.7262 2.00926 9.39193 1.4557 8.00021 1.45411Z"
                    fill="#008755"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3853_74228">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <span className="address d-block singleLine">
              {lang?.startsWith("ar") ? addressAr : addressEn}
            </span>
          </div>
        </Col>
        <Col xl={3} lg={4} xs={12} className="d-none d-lg-flex">
          <div className="icons d-flex align-items-center ms-auto">
            {isPrimary && (
              <span className="icon">
                <Badge pill bg="primary">
                  {t("Primary")}
                </Badge>
              </span>
            )}
            <span
              className="icon"
              onClick={() => onEditClick({ ...branch, isEdit: true })}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_3853_74203)">
                  <path
                    d="M19 0H5C2.243 0 0 2.243 0 5V19C0 21.757 2.243 24 5 24H19C21.757 24 24 21.757 24 19V5C24 2.243 21.757 0 19 0ZM22 19C22 20.654 20.654 22 19 22H5C3.346 22 2 20.654 2 19V5C2 3.346 3.346 2 5 2H19C20.654 2 22 3.346 22 5V19ZM13.879 6.379L7.172 13.086C6.417 13.841 6 14.846 6 15.914V17.5C6 18.053 6.448 18.5 7 18.5H8.586C9.654 18.5 10.659 18.084 11.414 17.328L18.121 10.621C19.291 9.451 19.291 7.549 18.121 6.379C16.987 5.246 15.012 5.246 13.879 6.379ZM10 15.914C9.627 16.286 9.112 16.5 8.586 16.5H8V15.914C8 15.38 8.208 14.878 8.586 14.5L12.836 10.25L14.25 11.664L10 15.914ZM16.707 9.207L15.664 10.25L14.25 8.836L15.293 7.793C15.67 7.414 16.329 7.414 16.707 7.793C17.097 8.183 17.097 8.817 16.707 9.207Z"
                    fill="#737373"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3853_74203">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <span
              className="icon"
              onClick={(e) => {
                e.preventDefault();
                onDelete(index);
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 4H17V2C17 1.46957 16.7893 0.960859 16.4142 0.585786C16.0391 0.210714 15.5304 0 15 0L9 0C8.46957 0 7.96086 0.210714 7.58579 0.585786C7.21071 0.960859 7 1.46957 7 2V4H2V6H4V21C4 21.7956 4.31607 22.5587 4.87868 23.1213C5.44129 23.6839 6.20435 24 7 24H17C17.7957 24 18.5587 23.6839 19.1213 23.1213C19.6839 22.5587 20 21.7956 20 21V6H22V4ZM9 2H15V4H9V2ZM18 21C18 21.2652 17.8946 21.5196 17.7071 21.7071C17.5196 21.8946 17.2652 22 17 22H7C6.73478 22 6.48043 21.8946 6.29289 21.7071C6.10536 21.5196 6 21.2652 6 21V6H18V21Z"
                  fill="#737373"
                />
                <path d="M11 10H9V18H11V10Z" fill="#737373" />
                <path d="M15 10H13V18H15V10Z" fill="#737373" />
              </svg>
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BranchTab;
