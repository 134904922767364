import React, { useState } from "react";
import ActivateMembershipComponent from "../../Components/auth/activateMembershipComponent";
import OtpVerification from "../../Components/auth/otpVerification";
import EmailVerification from "../../Components/auth/emailVerification";

const ActivationFlow = () => {
  const [current, setCurrent] = useState(0);
  const [state, setState] = useState({
    mobile_no: null,
    email: null,
    emiratesId: null,
    type: null,
    status: null,
  });

  return (
    <>
      {current === 0 && (
        <ActivateMembershipComponent
          setCurrent={setCurrent}
          setState={setState}
          state={state}
        />
      )}
      {current === 1 && (
        <OtpVerification
          setCurrent={setCurrent}
          state={state}
          setState={setState}
          type="REGISTRATION"
        />
      )}
      {current === 2 && (
        <EmailVerification
          setCurrent={setCurrent}
          state={state}
          setState={setState}
        />
      )}
    </>
  );
};

export default ActivationFlow;
