import { useState } from "react";

const useApi = () => {
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState([]);

  const invokeApi = async (apiHandler) => {
    try {
      setLoading(true);
      const response = await apiHandler();
      // setData(response);
      return response;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // return [loading, data, invokeApi];
  return [loading, invokeApi];
};

export default useApi;
