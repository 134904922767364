import React from "react";
import { Image, Nav } from "react-bootstrap";
import CardCategory from "../CardCategory";
import { useNavigate } from "react-router-dom";
import SearchCard from "../SearchCard";
import { useTranslation } from "react-i18next";
import { resetSearch } from "../../../redux/searchSlice";
import { useDispatch } from "react-redux";
import { extractCategoryName } from "../../../utils/utilityFunctions";

const SearchBody = ({
  searchResults,
  setSearchText,
  trendsHistory,
  searchText,
  setIsSearchFocused,
  loading = false,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClear = (e) => {
    e.stopPropagation();
    setSearchText("");
    dispatch(resetSearch());
  };

  return (
    <div className="search-dropdown-results">
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      ) : (
        <>
          {searchText !== "" && searchResults?.outlets?.length === 0 && (
            <div className="block">
              <div className="src-resultInfo d-md-flex align-items-center">
                <div className="imgHolder">
                  <Image
                    src="/images/search-result-img.png"
                    alt="UAE Flag"
                    fluid
                    className="img-fluid d-block w-100"
                  />
                </div>
                <div className="text">
                  <span className="title d-block">
                    {t("Your search")} <strong>“{searchText}”</strong>{" "}
                    {t("notMatchText")}.
                  </span>
                  <p className="fw-700 info-title">
                    {t("Try these suggestions")}
                  </p>
                  <ul>
                    <li>
                      {t("Make sure that all words are spelled correctly.")}
                    </li>
                    <li>{t("Try different keywords")}</li>
                  </ul>
                </div>
              </div>
              <div className="exploreOffers-list">
                <span className="block-title d-block fw-700">
                  {t("Explore offers in")}
                </span>
                <div className="exp-OffersWrap d-xl-flex flex-wrap">
                  {searchResults?.categories?.map((category, index) => (
                    <React.Fragment key={index}>
                      <CardCategory
                        to={`/categories/${extractCategoryName(
                          category?.deeplink
                        )}?sort=nearest`}
                        state={{
                          category_name: category?.title,
                        }}
                        imageSrc={category?.image_url_web}
                        alt={category?.title}
                        color={category?.color}
                        title={category?.title}
                      />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          )}
          {searchText === "" && trendsHistory && (
            <>
              {trendsHistory?.history?.length > 0 && (
                <div className="block">
                  <div className="d-flex justify-content-between">
                    <span className="block-title d-block fw-700">
                      {t("Recent history")}
                    </span>
                    <span
                      className="primary-color fw-700 cursor-pointer link"
                      onClick={onClear}
                    >
                      {t("Clear all")}
                    </span>
                  </div>

                  <Nav as="ul" className="tagList">
                    {trendsHistory?.history?.map((history, index) => (
                      <Nav.Item as="li" key={history + index}>
                        <div
                          className="iconAndText"
                          onClick={() => setSearchText(history)}
                        >
                          <span className="icon me-2">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.672 2.05027C8.93829 -0.683419 4.50612 -0.683419 1.77243 2.05024C1.59434 2.22833 1.42598 2.41591 1.2681 2.61216V1.64824C1.2681 1.38084 1.05135 1.16409 0.783953 1.16409C0.516558 1.16409 0.299805 1.38084 0.299805 1.64824V4.32412C0.299805 4.59152 0.516558 4.80827 0.783953 4.80827H3.45984C3.72723 4.80827 3.94399 4.59152 3.94399 4.32412C3.94399 4.05673 3.72723 3.83997 3.45984 3.83997H1.58337C2.68339 2.05738 4.6275 0.970932 6.72219 0.968225C10.0481 0.968225 12.7539 3.67397 12.7539 6.9999C12.7539 10.3258 10.0481 13.0316 6.72219 13.0316C5.12165 13.036 3.58587 12.3999 2.45714 11.2651C2.26727 11.0768 1.96072 11.0781 1.77246 11.268C1.58534 11.4567 1.58534 11.761 1.77246 11.9497C4.50612 14.6834 8.93829 14.6834 11.672 11.9497C14.4056 9.21605 14.4056 4.78396 11.672 2.05027Z"
                                fill="#282828"
                              />
                              <path
                                d="M5.32847 9.07866L7.06452 7.34247C7.15536 7.25169 7.20638 7.12853 7.20641 7.00013V2.20558C7.20641 1.93819 6.98966 1.72144 6.72226 1.72144C6.45487 1.72144 6.23811 1.93819 6.23811 2.20558V6.79959L4.64375 8.39398C4.45467 8.58306 4.4547 8.88961 4.64378 9.07869C4.83283 9.26775 5.13938 9.26775 5.32847 9.07866Z"
                                fill="#282828"
                              />
                            </svg>
                          </span>
                          <span>{history}</span>
                        </div>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              )}
              {trendsHistory?.trends && (
                <div className="block">
                  <span className="block-title d-block fw-700">
                    {t("Trending")}
                  </span>
                  <Nav as="ul" className="tagList">
                    {trendsHistory?.trends?.map((trend) => (
                      <Nav.Item as="li" key={trend}>
                        <div
                          className="iconAndText"
                          onClick={() => setSearchText(trend)}
                        >
                          <span className="icon me-2">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.3269 3.19995C9.97619 3.19995 9.79527 3.61918 10.0359 3.87436L10.5153 4.38282C10.6606 4.53691 10.6606 4.77754 10.5153 4.93163L8.13704 7.454C7.97915 7.62146 7.71286 7.62146 7.55496 7.454L5.73723 5.52611C5.57927 5.35857 5.31283 5.35866 5.15498 5.5263L1.25837 9.66465C1.11326 9.81876 1.11334 10.0592 1.25855 10.2133L1.55496 10.5276C1.71285 10.6951 1.97915 10.6951 2.13704 10.5276L5.15496 7.32681C5.31285 7.15935 5.57914 7.15935 5.73704 7.32681L7.5552 9.25516C7.713 9.42253 7.97911 9.42263 8.13705 9.25539L11.335 5.86906C11.4929 5.70182 11.759 5.70192 11.9168 5.86929L12.309 6.28522C12.5573 6.54865 13 6.37287 13 6.01081V3.59995C13 3.37904 12.8209 3.19995 12.6 3.19995H10.3269Z"
                                fill="#008755"
                              />
                            </svg>
                          </span>
                          <span>{trend}</span>
                        </div>
                      </Nav.Item>
                    ))}
                  </Nav>
                </div>
              )}
            </>
          )}
          {searchText !== "" && searchResults?.outlets?.length !== 0 && (
            <div className="block">
              <div className="d-flex justify-content-between">
                <span className="block-title d-block fw-700">
                  {t("Suggestions")}
                </span>
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    navigate(`/search/${searchText}`);
                    setSearchText("");
                    setIsSearchFocused(false);
                  }}
                  className="primary-color fw-500 text-decoration-none viewLink cursor-pointer"
                >
                  {t("View all")}
                </span>
              </div>
              <div className="searchCards">
                {searchResults?.outlets?.map((outlet) => (
                  <SearchCard
                    to={`/merchant-detail/${outlet?.merchant_id}`}
                    imageUrl={outlet?.image_url}
                    offerTitle={outlet?.merchant_name}
                    merchant_id={outlet?.merchant_id}
                    address={outlet?.address}
                    offerType={outlet?.type}
                    discount={outlet?.discount}
                    rating={outlet?.avg_rating}
                    distance={outlet?.distance}
                    key={outlet?.id}
                    tags={outlet?.tags}
                    searchText={searchText}
                    setIsSearchFocused={setIsSearchFocused}
                    setSearchText={setSearchText}
                  />
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SearchBody;
