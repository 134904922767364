import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  APIProvider,
  ControlPosition,
  Map,
  Marker,
} from "@vis.gl/react-google-maps";
import { CustomMapControl } from "./map/map-control";
import MapHandler from "./map/map-handler";
import { useSelector } from "react-redux";
import { selectLangState } from "../../redux/configurationSlice";

const LocationCoordinate = ({ show, close, setValue, ...rest }) => {
  const { t } = useTranslation();
  const { lang } = useSelector(selectLangState);
  const [center, setCenter] = useState({ lat: 25.266666, lng: 55.316666 });
  const [zoom, setZoom] = useState(10);

  const [selectedPlace, setSelectedPlace] = useState(null);

  return (
    <Modal show={show} className="accountModal" centered {...rest}>
      <Modal.Header className="d-flex flex-column align-items-start">
        <Modal.Title className="font-bukra fw-500">
          {t("Location coordinates")}
        </Modal.Title>
        <p>{t("Search for a location to get the coordinates")}</p>
      </Modal.Header>

      <Button
        className="closeBtn primary-color text-decoration-none fw-700"
        variant="link"
        onClick={() => close()}
      >
        {t("Close")}
      </Button>
      <Modal.Body className="text-center">
        <div className="map" style={{ height: "300px" }}>
          <APIProvider
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            libraries={["places", "geometry"]}
          >
            <Map
              gestureHandling={"greedy"}
              disableDefaultUI={true}
              onCenterChanged={(e) => {
                setCenter(e.detail?.center);
                setZoom(e.detail?.zoom);
              }}
              onClick={(e) => {
                setCenter(e.detail?.latLng);
              }}
              onZoomChanged={(e) => {
                setZoom(e.detail?.zoom);
                setCenter(e.detail?.center);
              }}
              center={center}
              zoom={zoom}
            >
              <Marker position={center} />
            </Map>
            <CustomMapControl
              controlPosition={
                lang == "ar"
                  ? ControlPosition.RIGHT_TOP
                  : ControlPosition.TOP_LEFT
              }
              onPlaceSelect={setSelectedPlace}
            />

            <MapHandler place={selectedPlace} />
          </APIProvider>
        </div>
        <div className="d-flex align-items-center justify-content-end mt-3">
          <div className="d-flex gap-3">
            <Button
              variant="outline"
              className="fw-500 font-dubai d-block mb-3 mb-md-0 px-5"
              onClick={close}
            >
              {t("Cancel")}
            </Button>
            <Button
              className="fw-500 font-dubai d-block mb-3 mb-md-0"
              onClick={() => {
                setValue("latitude", center.lat);
                setValue("longitude", center.lng);
                close();
              }}
            >
              {t("Get Coordinates")}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LocationCoordinate;
