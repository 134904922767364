import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import HappinessMetter from "../Modal/HappinessMetter";
import { selectIsAuthenticated } from "../../redux/authSlice";
import { useSelector } from "react-redux";

const Footer = ({ loggedIn }) => {
  const { t } = useTranslation();
  const [happinessShow, setHappinessShow] = useState(false);
  const toggleHappinessMetter = () => setHappinessShow((prev) => !prev);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return (
    <footer id="footer">
      <Container className="footer-top">
        <Row>
          <Col lg={6} xs={12}>
            <Row>
              <Col lg={7} xs={6}>
                <Nav as="ul" className="d-block">
                  <Nav.Item as="li">
                    <Link to="/home">{t("Home")}</Link>
                  </Nav.Item>
                  {loggedIn && (
                    <Nav.Item as="li">
                      <Link to="/categories">{t("Categories")}</Link>
                    </Nav.Item>
                  )}
                  <Nav.Item as="li">
                    <Link to="/about">{t("About us")}</Link>
                  </Nav.Item>
                  {!isAuthenticated && (
                    <Nav.Item as="li">
                      <Link to="/activate-membership">
                        {t("Member Registration")}
                      </Link>
                    </Nav.Item>
                  )}
                  <Nav.Item as="li">
                    <Link to="/register-company">
                      {t("Company Registration")}
                    </Link>
                  </Nav.Item>
                  {/* <Nav.Item as="li">
                    <a href="images\Esaad_UserManual.pdf" download>
                      {t("User Manuals")}
                    </a>
                  </Nav.Item> */}
                </Nav>
              </Col>
              <Col lg={5} xs={6}>
                <Nav as="ul" className="d-block">
                  <Nav.Item as="li">
                    <Link to="/partner-facilities">
                      {t("Partner Facilities")}
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link to="/media-center/news">{t("News")}</Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link to="/media-center/success-stories">
                      {t("Success Stories")}
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link to="/contact">{t("Inquiries")}</Link>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link to="/faqs">{t("FAQS")}</Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
          </Col>
          <Col lg={6} xs={12}>
            <div className="footer-logos">
              <Image
                src="/images/footer-logos.png"
                alt="Footer Logos"
                className="d-block img-fluid w-100"
              />
            </div>
          </Col>
        </Row>
      </Container>
      <div className="footerBottom">
        {/* smilly icon */}
        <div
          className="bottomLeft cursor-pointer"
          style={{ zIndex: 999 }}
          onClick={toggleHappinessMetter}
        >
          <svg
            width="76"
            height="76"
            viewBox="0 0 76 76"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M37.9925 76C30.4775 75.999 23.1316 73.7695 16.8836 69.5934C10.6356 65.4173 5.76614 59.4822 2.89097 52.5385C0.0157939 45.5948 -0.735968 37.9545 0.730739 30.5835C2.19745 23.2126 5.81675 16.4421 11.131 11.1282C16.4452 5.81429 23.2158 2.19564 30.5864 0.72981C37.9571 -0.736022 45.5968 0.0167971 52.5397 2.89307C59.4825 5.76935 65.4166 10.6399 69.5916 16.8889C73.7666 23.1378 75.995 30.4845 75.995 38C75.9831 48.0754 71.9752 57.7347 64.8508 64.8587C57.7264 71.9826 48.0673 75.9894 37.9925 76ZM37.9925 7.51881C31.9645 7.5198 26.0721 9.30834 21.0605 12.6583C16.0489 16.0082 12.1431 20.769 9.83697 26.3388C7.53084 31.9086 6.92797 38.0372 8.10458 43.9497C9.28119 49.8621 12.1844 55.2929 16.4472 59.5553C20.71 63.8176 26.1409 66.7202 32.0532 67.8959C37.9654 69.0716 44.0935 68.4677 49.6626 66.1605C55.2316 63.8533 59.9915 59.9464 63.3404 54.9339C66.6893 49.9214 68.4767 44.0284 68.4767 38C68.4674 29.9179 65.2525 22.1695 59.5375 16.455C53.8225 10.7406 46.0741 7.52677 37.9925 7.51881Z"
              fill="#008755"
            />
            <path
              d="M38.7285 59.9598C25.2206 59.9598 15.4919 47.4285 15.0859 46.8721L21.0454 42.3157L18.0381 44.5964L21.0454 42.3057C21.1256 42.4109 28.9647 52.441 38.7185 52.441H38.7535C44.3622 52.441 49.7804 49.0225 54.9129 42.3157L60.8924 46.8721C54.2763 55.5588 46.8182 59.9598 38.7285 59.9598Z"
              fill="#008755"
            />
          </svg>
        </div>

        <HappinessMetter
          happinessShow={happinessShow}
          setHappinessShow={setHappinessShow}
        />
        <Container>
          <Row>
            <Col lg={8} xs={12}>
              <div className="d-lg-flex align-items-center col-left">
                <div className="imgHolder me-4">
                  <Link to="/">
                    <Image
                      src="/images/Esaad_Logo.png"
                      alt="Esaad Logo"
                      className="d-block img-fluid w-100"
                    />
                  </Link>
                </div>
                <div className="text">
                  <p>© esaad 2024. {t("All Rights Reserved")}.</p>
                  <div className="links">
                    <Link to="/terms-and-condition">
                      {t("Terms & Conditions")}
                    </Link>
                    <Link to="/privacy-policy">{t("Privacy Policy")}</Link>
                    <Link to="/home">www.dubaipolice.gov.ae</Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} xs={12}>
              <ul className="list-unstyled d-flex justify-content-end socialIcons">
                <li>
                  <Link
                    to="https://www.youtube.com/channel/UCMebk44F_zVLj-7aD_mSUhQ"
                    target="_blank"
                    aria-label="external link to youtube page"
                    rel="noopener noreferrer"
                  >
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.28032 6.38993H8.61578C9.35821 6.38993 9.96128 5.79052 9.96578 5.04809V2.86355C9.95249 2.13075 9.35688 1.54245 8.62394 1.53809H8.28848C7.54605 1.53809 6.94298 2.1375 6.93848 2.87993V5.06447C6.95184 5.79727 7.54738 6.38564 8.28032 6.38993ZM7.94486 2.78177C7.94507 2.50165 8.17232 2.27461 8.45245 2.27482C8.73236 2.27503 8.95926 2.50186 8.9594 2.78177V5.12993C8.95919 5.41006 8.73194 5.63709 8.45181 5.63688C8.1719 5.63667 7.94507 5.40984 7.94486 5.12993V2.78177Z"
                        fill="black"
                      />
                      <path
                        d="M4.29471 6.30816H5.48109V3.69816L6.83109 0H5.72655L4.90833 2.45454L4.09017 0H2.87109L4.31109 3.69816L4.29471 6.30816Z"
                        fill="black"
                      />
                      <path
                        d="M11.6584 6.38993C12.1359 6.34402 12.5834 6.1361 12.9265 5.80085V6.30816H13.9084V1.53816H12.9265V5.23631C12.9265 5.23631 12.5911 5.62901 12.272 5.62901C11.9529 5.62901 11.9365 5.37539 11.9365 5.37539V1.53809H10.8975V5.73539C10.9542 6.11184 11.2777 6.39014 11.6584 6.38993Z"
                        fill="black"
                      />
                      <path
                        d="M10.9878 11.8711C10.7906 11.8807 10.6065 11.9728 10.4805 12.1247V15.512C10.6099 15.6552 10.7948 15.7357 10.9878 15.7329C11.4951 15.7329 11.4951 15.1684 11.4951 15.1684V12.4357C11.4951 12.4356 11.4051 11.8711 10.9878 11.8711Z"
                        fill="#D42927"
                      />
                      <path
                        d="M14.8745 11.8711C14.3672 11.8711 14.3672 12.4356 14.3672 12.4356V13.1638H15.3817V12.4356C15.3818 12.4356 15.3818 11.8711 14.8745 11.8711Z"
                        fill="#D42927"
                      />
                      <path
                        d="M15.2525 7.43641C15.2383 7.43663 15.2241 7.43698 15.2099 7.43747H15.1935C15.1935 7.43747 12.109 7.26562 8.99993 7.26562C5.89085 7.26562 2.78993 7.42924 2.78993 7.42924C1.43508 7.38403 0.300093 8.44575 0.254953 9.8006C0.254038 9.82831 0.253547 9.85608 0.253617 9.88378C0.0973824 10.913 0.0125859 11.9519 0 12.9929C0.0115313 14.0447 0.0962574 15.0946 0.253617 16.1347C0.252843 17.4903 1.35119 18.5899 2.70682 18.5906C2.73452 18.5906 2.7623 18.5902 2.79 18.5892C2.79 18.5892 5.84184 18.7529 9 18.7529C12.1582 18.7529 15.21 18.5892 15.21 18.5892C16.5649 18.6345 17.6998 17.5727 17.745 16.2179C17.7459 16.1902 17.7464 16.1624 17.7463 16.1347C17.9029 15.0945 17.9876 14.0447 17.9999 12.9929C17.9896 11.9409 17.9048 10.891 17.7463 9.85101C17.7244 8.49567 16.608 7.41455 15.2525 7.43641ZM5.32631 10.1783H4.09085V16.5438H2.87177V10.1783H1.63631V9.13102H5.35085L5.32631 10.1783ZM8.54177 16.5438H7.51908V16.0611C7.17103 16.3852 6.72469 16.5839 6.25092 16.6256C5.87032 16.6336 5.54372 16.3561 5.49 15.9792V10.9802H6.54546V15.652C6.54546 15.652 6.54546 15.8975 6.88092 15.8975C7.21638 15.8975 7.56 15.4965 7.56 15.4965V10.9802H8.57454L8.54177 16.5438ZM11.5772 16.6256C11.1469 16.6295 10.7401 16.4292 10.4809 16.0856V16.5438H9.37631V9.13109H10.4727V11.5365C10.7533 11.2187 11.1464 11.0221 11.5691 10.9884C12.2482 10.9884 12.5018 11.5529 12.5018 12.2811L12.5099 15.3329C12.5099 15.3329 12.5099 16.6256 11.5772 16.6256ZM16.4781 13.9911H14.3672V15.202C14.3672 15.202 14.3672 15.7665 14.8745 15.7665C15.3818 15.7665 15.3818 15.202 15.3818 15.202V14.6292H16.4782V15.5129C16.3314 16.2212 15.6768 16.7069 14.9563 16.6419C14.21 16.7092 13.522 16.2344 13.3199 15.5129V12.3547C13.3427 11.5687 13.9982 10.9501 14.7842 10.9729C14.8418 10.9745 14.8993 10.9797 14.9563 10.9883C15.6855 10.884 16.3611 11.3904 16.4655 12.1195C16.4767 12.1973 16.4809 12.2761 16.4782 12.3547L16.4781 13.9911Z"
                        fill="#D42927"
                      />
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.facebook.com/dubaipolicehq.en/"
                    target="_blank"
                    aria-label="external link to facebook page"
                    rel="noopener noreferrer"
                  >
                    <svg
                      width="9"
                      height="16"
                      viewBox="0 0 9 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.36295 3.90948V5.54587H0.726562V8.00041H2.36295V15.364H5.63564V8.00041H7.81202L8.09018 5.54587H5.63564V4.11402C5.63564 3.45133 5.7011 3.09948 6.7238 3.09948H8.09018V0.636719H5.89748C3.27933 0.636789 2.36295 1.86402 2.36295 3.90948Z"
                        fill="#3D6AD6"
                      />
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.instagram.com/dubaipolicehq/?hl=en"
                    target="_blank"
                    aria-label="external link to intagram"
                    rel="noopener noreferrer"
                  >
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_15178_65262)">
                        <path
                          d="M11.7506 0.51709H5.28358C2.65532 0.51709 0.51709 2.65532 0.51709 5.28358V11.7506C0.51709 14.3789 2.65532 16.5171 5.28358 16.5171H11.7506C14.3789 16.5171 16.5171 14.3789 16.5171 11.7506V5.28358C16.5171 2.65532 14.3788 0.51709 11.7506 0.51709ZM14.9075 11.7506C14.9075 13.4941 13.4941 14.9075 11.7506 14.9075H5.28358C3.54009 14.9075 2.12669 13.4941 2.12669 11.7506V5.28358C2.12669 3.54006 3.54009 2.12669 5.28358 2.12669H11.7506C13.4941 2.12669 14.9075 3.54006 14.9075 5.28358V11.7506Z"
                          fill="url(#paint0_linear_15178_65262)"
                        />
                        <path
                          d="M8.51708 4.37891C6.23529 4.37891 4.37891 6.23528 4.37891 8.51705C4.37891 10.7988 6.23529 12.6552 8.51708 12.6552C10.7989 12.6552 12.6552 10.7988 12.6552 8.51705C12.6552 6.23526 10.7989 4.37891 8.51708 4.37891ZM8.51708 11.0456C7.12057 11.0456 5.98851 9.91358 5.98851 8.51708C5.98851 7.12057 7.1206 5.98851 8.51708 5.98851C9.91358 5.98851 11.0456 7.12057 11.0456 8.51708C11.0456 9.91355 9.91355 11.0456 8.51708 11.0456Z"
                          fill="url(#paint1_linear_15178_65262)"
                        />
                        <path
                          d="M12.6635 5.40164C13.2111 5.40164 13.6551 4.95769 13.6551 4.41005C13.6551 3.86241 13.2111 3.41846 12.6635 3.41846C12.1158 3.41846 11.6719 3.86241 11.6719 4.41005C11.6719 4.95769 12.1158 5.40164 12.6635 5.40164Z"
                          fill="url(#paint2_linear_15178_65262)"
                        />
                      </g>
                      <defs>
                        <linearGradient
                          id="paint0_linear_15178_65262"
                          x1="8.51709"
                          y1="16.4705"
                          x2="8.51709"
                          y2="0.641365"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#E09B3D" />
                          <stop offset="0.3" stopColor="#C74C4D" />
                          <stop offset="0.6" stopColor="#C21975" />
                          <stop offset="1" stopColor="#7024C4" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_15178_65262"
                          x1="8.51708"
                          y1="16.4705"
                          x2="8.51708"
                          y2="0.641351"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#E09B3D" />
                          <stop offset="0.3" stopColor="#C74C4D" />
                          <stop offset="0.6" stopColor="#C21975" />
                          <stop offset="1" stopColor="#7024C4" />
                        </linearGradient>
                        <linearGradient
                          id="paint2_linear_15178_65262"
                          x1="12.6635"
                          y1="16.4706"
                          x2="12.6635"
                          y2="0.641424"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#E09B3D" />
                          <stop offset="0.3" stopColor="#C74C4D" />
                          <stop offset="0.6" stopColor="#C21975" />
                          <stop offset="1" stopColor="#7024C4" />
                        </linearGradient>
                        <clipPath id="clip0_15178_65262">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0.51709 0.51709)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://twitter.com/DubaiPoliceHQ"
                    target="_blank"
                    aria-label="external link to twitter"
                    rel="noopener noreferrer"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.4893 6.77491L15.3176 0H13.9365L8.87577 5.88256L4.8338 0H0.171875L6.28412 8.89547L0.171875 16H1.55307L6.8973 9.78782L11.1659 16H15.8278L9.48896 6.77491H9.4893ZM7.59756 8.97384L6.97826 8.08805L2.05073 1.03974H4.17217L8.14874 6.72795L8.76804 7.61374L13.9371 15.0075H11.8157L7.59756 8.97418V8.97384Z"
                        fill="black"
                      />
                    </svg>
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
