import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import CardCategory from "../Global/CardCategory";
import { useQuery } from "@tanstack/react-query";
import { HomeAPI } from "../../apis/homeApi";
import { useSelector } from "react-redux";
import { selectLangState } from "../../redux/configurationSlice";
import { extractCategoryName } from "../../utils/utilityFunctions";

const MainCategories = ({ categories }) => {
  const { lang } = useSelector(selectLangState);
  // const categories = useQuery({
  //   queryKey: ["categories"],
  //   queryFn: HomeAPI.getCategories,
  // });

  return (
    <section className="main-categories">
      <Container>
        <Row xs={1} className="g-0">
          <Col>
            <div className="position-relative">
              <Swiper
                key={lang?.startsWith("ar") ? "rtl" : "ltr"}
                dir={lang?.startsWith("ar") ? "rtl" : "ltr"}
                navigation={{
                  prevEl: ".category-swiper-prev",
                  nextEl: ".category-swiper-next",
                }}
                modules={[Navigation]}
                className="Category-swiper"
                breakpoints={{
                  320: {
                    slidesPerView: 4,
                    spaceBetween: 6,
                  },
                  768: {
                    slidesPerView: 6,
                    spaceBetween: 14,
                  },
                  1200: {
                    slidesPerView: 9,
                    spaceBetween: 14,
                  },
                }}
              >
                {categories?.map((category, index) => (
                  <SwiperSlide key={index}>
                    <CardCategory
                      to={{
                        pathname: `/categories/${extractCategoryName(
                          category?.deeplink
                        )}`,
                        search: "?sort=nearest",
                      }}
                      state={{
                        category_name: category?.title,
                      }}
                      imageSrc={category?.image_url_web}
                      alt={category?.title}
                      title={category?.title}
                    />
                  </SwiperSlide>
                ))}
                <div className="swiper-button-prev category-swiper-prev slider-btn">
                  <span></span>
                </div>
                <div className="swiper-button-next category-swiper-next slider-btn">
                  <span></span>
                </div>
              </Swiper>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default MainCategories;
