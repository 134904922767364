import React, { forwardRef } from "react";
import Form from "react-bootstrap/Form";
import { TEXT_LIMITS } from "../../utils/constants";

const InputField = forwardRef(
  ({ className, maxLength = TEXT_LIMITS.TEXTBOX, ...rest }, ref) => {
    return (
      <Form.Control
        className={`d-block ${className}`}
        {...rest}
        ref={ref}
        maxLength={maxLength}
      />
    );
  }
);

export default InputField;
