import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CardFavourite from "../Global/CardFavourite";
import EmptyState from "../Global/EmptyState";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { selectLangState } from "../../redux/configurationSlice";
import { AuthAPI } from "../../apis/authApi";

const MyFavorites = () => {
  const { t } = useTranslation();
  const { lang } = useSelector(selectLangState);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { data, refetch } = useQuery({
    queryKey: [
      "favorites",
      {
        company: "ESD",
        location_id: 0,
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "WEB",
        favourites: true,
        limit: pageSize,
        offset: (currentPage - 1) * pageSize,
      },
    ],
    queryFn: ({ queryKey }) => AuthAPI.getFavorites({ ...queryKey[1] }),
  });

  const changeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="profile-content">
      <div className="titleHead">
        <h2 className="font-bukra fw-700 m-0 text-black">
          {t("My Favourites")}
        </h2>
      </div>
      <div className="content-body">
        {data?.outlets?.length > 0 ? (
          <div className="hrCards">
            {data?.outlets?.map((outlet, index) => (
              <React.Fragment key={index}>
                <CardFavourite
                  to={`/merchant-detail/${outlet?.merchant_id}`}
                  imageUrl={outlet?.image_url ?? "/images/searchCard-img.png"}
                  offerTitle={outlet?.merchant_name}
                  validity={outlet?.valid_till_date}
                  tags={outlet?.tags}
                  offerType="Car Maintenance"
                  discount={outlet?.distance}
                  rating={outlet?.avg_rating}
                  isFavorite={outlet?.is_favourite}
                  outlet={outlet}
                  refetch={refetch}
                  key={outlet?.id}
                />
              </React.Fragment>
            ))}
            {pageSize * currentPage < data?.pagination?.total_records && (
              <div className="text-center">
                <span
                  className="link primary-color fw-700"
                  style={{ cursor: "pointer" }}
                  onClick={() => changeCurrentPage(currentPage + 1)}
                >
                  {t("Load more")}
                </span>
              </div>
            )}
          </div>
        ) : (
          <EmptyState
            className=""
            imageUrl="/images/favOutline.svg"
            text={<>{t("You don't have any place in your favourites yet")}</>}
            btnText={`${t("Explore offers")}`}
            url="/categories"
          />
        )}
      </div>
    </div>
  );
};

export default MyFavorites;
