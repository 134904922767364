import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Image,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InputField from "../../Form/InputField";
import OTPInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { handleUSer, selectUserDetails } from "../../../redux/authSlice";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AuthAPI } from "../../../apis/authApi";
import { toast } from "react-toastify";
import { HomeAPI } from "../../../apis/homeApi";
import { selectLangState } from "../../../redux/configurationSlice";
import ReactSelect from "react-select";
import ButtonWithLoading from "../../buttons/ButtonWithLoading";
import FieldValidationText from "../../Global/fieldValidationText";
import { MoengageService } from "../../../services/moengage";

const CountryModal = ({ show, close, ...rest }) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(1);
  const [countries, setCountries] = useState([]);
  const user = useSelector(selectUserDetails);
  const { lang } = useSelector(selectLangState);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    watch,
    register,
    setValue,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm({
    mode: "all",
  });
  const { data } = useQuery({
    queryKey: [
      "getconfig",
      {
        company: "ESD",
        location_id: 0,
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
      },
    ],
    queryFn: ({ queryKey }) => HomeAPI.getConfiguration({ ...queryKey[1] }),
  });

  const handleClose = () => {
    close();
    setTab(1);
  };
  const onSuccess = (response) => {
    setTab(2);
    dispatch(handleUSer({ user: response?.user_profile }));
    MoengageService.userProfileDetails({
      "Account Status": response?.user_profile?.status,
      user_country_of_residence: response?.user_profile?.country_of_residence,
      user_preferred_location: response?.user_profile?.preferred_location ?? "",
      user_preferred_currency: response?.user_profile?.preferred_currency ?? "",
      user_app_language: lang?.startsWith("ar") ? "ar" : "en",
      is_email_verified: response?.user_profile?.is_email_verified,
    });
  };
  const onError = (error) => {
    toast.error(error?.message);
  };
  const {
    mutateAsync: update,
    status,
    error,
  } = useMutation({
    mutationFn: (data) => AuthAPI.updateUser(data),
    onSuccess,
    onError,
  });

  const onSubmit = async (data) => {
    try {
      const newUser = { country_of_residence: data?.country };
      let res = await update(newUser);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setValue("country", user?.country_of_residence);
  }, [user]);

  const mapArray = (data) => {
    const countriesList = data?.map((country) => {
      return { label: country?.name, value: country?.country_id };
    });
    setCountries(countriesList);
  };
  useEffect(() => {
    if (data) {
      mapArray(data?.country_of_residence ?? []);
    }
  }, [data]);

  return (
    <Modal show={show} className="accountModal" centered {...rest}>
      <Modal.Header>
        <Modal.Title className="font-bukra fw-500">
          {t("Edit Country of Residence")}
        </Modal.Title>
      </Modal.Header>

      <Button
        className="closeBtn primary-color text-decoration-none fw-700"
        variant="link"
        disabled={status == "pending"}
        onClick={handleClose}
      >
        {t("Close")}
      </Button>
      <Modal.Body className="text-center">
        {tab === 1 && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <p className="mb-4 text-start">
              {t("Please select your new country that you want to update.")}
            </p>
            <Form.Group className="mb-3">
              <p className="text-start fw-500 text-black">{t("Country")}</p>
              <Controller
                control={control}
                name="country"
                rules={{
                  required: t("Country is required"),
                }}
                render={({ field }) => (
                  <ReactSelect
                    {...field}
                    options={countries}
                    placeholder={t("Select Country")}
                    className="text-start"
                    value={countries?.find((c) => c.label === field.value)}
                    onChange={(value) => field.onChange(value?.label)}
                  />
                )}
              />
              {errors.country && (
                <div className=" text-start mt-1">
                  <FieldValidationText errorMessage={errors.country?.message} />
                </div>
              )}
            </Form.Group>
            <ButtonWithLoading
              variant="primary"
              type="submit"
              className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
              text={t("Update")}
              disabled={!isValid || !isDirty}
              isLoading={status == "pending"}
            />
          </Form>
        )}
        {tab === 2 && (
          <>
            <div className="circle-img d-inline-block">
              <Image
                src="/images/tick-img.png"
                alt="Trash image"
                fluid
                className="d-block w-100"
              />
            </div>
            <h4 className="font-bukra fw-500 mb-5">
              {t("Country of Residence has been Updated")}
            </h4>

            <Row>
              <Col md={12} xs={12}>
                <Button
                  variant="primary"
                  className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                  onClick={handleClose}
                >
                  {t("Done")}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CountryModal;
