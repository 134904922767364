import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import logger from "redux-logger";
import authReducer, { resetAuth } from "./authSlice";
import configurationReducer, { resetConfiguration } from "./configurationSlice";
import homeReducer, { resetHome } from "./homeSlice";
import searchReducer, { resetSearch } from "./searchSlice";
const persistConfig = {
  key: "auth",
  storage,
  blacklist: ["loading", "error", "email", "logoutState"],
  whitelist: ["isOnboarding", "userDetails", "token", "isAuthenticated"],
};

const persistConfigurationConfig = {
  key: "configuration",
  storage,
  blacklist: [],
};

const persistHomeConfig = {
  key: "home",
  storage,
  blacklist: [],
};

const persistSearchConfig = {
  key: "search",
  storage,
  blacklist: [],
  whitelist: ["history"],
};

const persistedReducer = persistReducer(persistConfig, authReducer);
const persistSearchReducer = persistReducer(persistSearchConfig, searchReducer);
const persistConfigurationReducer = persistReducer(
  persistConfigurationConfig,
  configurationReducer
);
const persistHomeReducer = persistReducer(persistHomeConfig, homeReducer);

export const store = configureStore({
  reducer: {
    auth: persistedReducer,
    configuration: persistConfigurationReducer,
    home: persistHomeReducer,
    search: persistSearchReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(logger),
});

export const persistor = persistStore(store);

export const resetReduxStore = () => {
  return (dispatch) => {
    dispatch(resetAuth());
    dispatch(resetConfiguration());
    dispatch(resetHome());
    dispatch(resetSearch());
    persistor.purge(); // Reset the persisted state as well
  };
};
