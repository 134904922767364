import React, { forwardRef } from "react";
import Form from "react-bootstrap/Form";

const InputField = forwardRef(({ className, ...rest }, ref) => {
  return (
    <Form.Control
      className={`d-block w-100 ${className}`}
      {...rest}
      ref={ref}
    />
  );
});

export default InputField;
