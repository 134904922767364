import Image from "react-bootstrap/Image";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const AppDownload = () => {
  const [device, setDevice] = useState({
    isMobile: true,
    deviceType: "others",
  });
  useEffect(() => {
    const userAgent = navigator.userAgent;
    // Check if the user is on a mobile device
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    ) {
      // Check if it's an iPhone
      if (/iPhone|iPod/i.test(userAgent)) {
        setDevice({ isMobile: true, deviceType: "iPhone" });
      }
      // Check if it's an iPad
      else if (/iPad/i.test(userAgent)) {
        setDevice({ isMobile: true, deviceType: "iPad" });
      }
      // Check if it's an Android device
      else if (/Android/i.test(userAgent)) {
        // Check if it's a Huawei device
        if (/Huawei/i.test(userAgent)) {
          setDevice({ isMobile: true, deviceType: "Huawei" });
        } else {
          setDevice({ isMobile: true, deviceType: "Android" });
        }
      }
      // For other mobile devices
      else {
        setDevice({ isMobile: true, deviceType: "others" });
      }
    } else {
      setDevice({
        isMobile: false,
        deviceType: "laptop",
      });
    }
  }, []);
  const { t } = useTranslation();
  return (
    <section className="appDownload">
      <div className="cover bg-primary">
        <Container>
          <Row>
            <Col xs={8}>
              <div className="textHolder">
                <h2 className="fw-700 text-white font-bukra">
                  {t("Download the esaad app now!")}
                  {/* <span className="d-block subHead">
                    {t("notDownloadSubHead")}
                  </span> */}
                </h2>
                <div className="QR-bar d-md-flex flex-md-row-reverse align-items-center justify-content-md-end">
                  <p className="m-md-0 text-white fw-400">
                    {t("scanAndDiscountText")}
                  </p>
                  <div className="QR-code me-md-4">
                    <Image
                      src="/images/QR-Code.png"
                      alt="Mobile App Image"
                      className="d-block img-fluid w-100"
                    />
                  </div>

                  <div to="" className="mt-3 d-inline-block d-md-none">
                    {device?.isMobile && (
                      <>
                        {["iPad", "iPhone"].indexOf(device?.deviceType) !==
                          -1 && (
                          <Link
                            to="https://apps.apple.com/us/app/esaad-card/id1475890066"
                            target="_blank"
                            className="me-2"
                            aria-label="external link for app store"
                            rel="noopener noreferrer"
                          >
                            <Image
                              src="/images/appStore.svg"
                              alt="App Store Image"
                              className="d-inline-block img-fluid"
                            />
                          </Link>
                        )}
                        {["Android", "others", "Huawei"].indexOf(
                          device?.deviceType
                        ) !== -1 && (
                          <Link
                            to="https://play.google.com/store/apps/details?id=dubaipolice.esaad.ae.esaad_dubaipolice"
                            target="_blank"
                            className="me-2"
                            aria-label="external link for google play store"
                            rel="noopener noreferrer"
                          >
                            <Image
                              src="/images/Google-Play.svg"
                              alt="App Store Image"
                              className="d-inline-block img-fluid"
                            />
                          </Link>
                        )}
                        {/* huawei */}
                        {/* {["Huawei"].indexOf(device?.deviceType) !== -1 && (
                          <span className="me-2">
                            <Image
                              src="/images/appGallery.svg"
                              alt="App Store Image"
                              className="d-inline-block img-fluid"
                            />
                          </span>
                        )} */}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={4} className="position-relative">
              <div className="imgHolder">
                <Image
                  src="/images/mobileApp.png"
                  alt="Mobile App Image"
                  className="d-block img-fluid w-100"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="d-none d-md-block">
        <Row>
          <Col xs={12}>
            <div className="app-download-imgs d-flex">
              <Link
                to="https://apps.apple.com/us/app/esaad-card/id1475890066"
                target="_blank"
                className="me-2"
                aria-label="external link for app store"
                rel="noopener noreferrer"
              >
                <Image
                  src="/images/appStore.svg"
                  alt="App Store Image"
                  className="d-inline-block img-fluid"
                />
              </Link>
              <Link
                to="https://play.google.com/store/apps/details?id=dubaipolice.esaad.ae.esaad_dubaipolice"
                target="_blank"
                className="me-2"
                aria-label="external link for google play store"
                rel="noopener noreferrer"
              >
                <Image
                  src="/images/Google-Play.svg"
                  alt="App Store Image"
                  className="d-inline-block img-fluid"
                />
              </Link>
              {/* Huawei */}
              {/* <Link
                to="https://play.google.com/store/apps/details?id=dubaipolice.esaad.ae.esaad_dubaipolice"
                target="_blank"
                className="me-2"
                aria-label="external link for google play store"
                rel="noopener noreferrer"
              >
                <Image
                  src="/images/appGallery.svg"
                  alt="App Store Image"
                  className="d-inline-block img-fluid"
                />
              </Link> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AppDownload;
