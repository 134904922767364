import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputLabel from "../Form/InputLabel";
import InputField from "../Form/InputField";
import MultiRangeSlider from "multi-range-slider-react";
import useOutside from "../../hooks/useOutside";
import CategoryDropDown from "../Global/CategoryDropDown";
import DiscountDropDown from "../Global/DiscountDropDown";
import { useTranslation } from "react-i18next";

const FilterBar = ({ banners }) => {
  const { t } = useTranslation();
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [discountOpen, setDiscountOpen] = useState(false);
  const [minValue, set_minValue] = useState(0);
  const [maxValue, set_maxValue] = useState(100);
  const ref = useOutside(() => setCategoryOpen(false));
  const discountRef = useOutside(() => setDiscountOpen(false));
  const handleInput = (val) => {
    if (val?.length > 1) {
      set_minValue(val[0]);
      set_maxValue(val[1]);
    }
  };

  return (
    <div className="filterBar d-none d-xl-block">
      <Container>
        <div className="filterBar-wrap">
          <Form>
            <Button variant="primary" type="submit" className="filterBtn">
              <svg
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30.2659 26.3055L23.1388 19.1779C24.3497 17.25 25.0525 14.9714 25.0525 12.526C25.0525 5.60769 19.4441 0 12.526 0C5.60795 0 0 5.60769 0 12.526C0 19.4446 5.60768 25.0518 12.526 25.0518C15.1872 25.0518 17.6522 24.2198 19.6805 22.806L26.7228 29.8488C27.2122 30.3377 27.8537 30.5812 28.4944 30.5812C29.1358 30.5812 29.7766 30.3377 30.2667 29.8488C31.2446 28.8699 31.2446 27.2842 30.2659 26.3055ZM12.526 20.9935C7.85003 20.9935 4.05903 17.2028 4.05903 12.5265C4.05903 7.85029 7.85003 4.05929 12.526 4.05929C17.2023 4.05929 20.993 7.85029 20.993 12.5265C20.993 17.2028 17.2023 20.9935 12.526 20.9935Z"
                  fill="white"
                />
              </svg>
            </Button>
            <Row className="g-0">
              <Col className="category">
                <div className="fieldWrap" ref={ref}>
                  <div className="labelWrap">
                    <span
                      className="icon"
                      onClick={() => setCategoryOpen((prev) => !prev)}
                    >
                      <svg
                        width="14"
                        height="8"
                        viewBox="0 0 14 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 1L7 7L1 0.999999"
                          stroke="#008755"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <InputLabel text={`${t("Category")}`} />
                  </div>
                  <InputField
                    type={"text"}
                    placeholder={t("e.g., Family")}
                    onFocus={() => setCategoryOpen(true)}
                  />

                  {categoryOpen && <CategoryDropDown />}
                </div>
              </Col>
              <Col className="type">
                <div className="fieldWrap">
                  <div className="labelWrap">
                    <InputLabel text={`${t("What are you looking for?")}`} />
                  </div>
                  <InputField
                    type={"text"}
                    placeholder={t("e.g., Food and Restaurants")}
                  />
                </div>
              </Col>
              <Col className="location">
                <div className="fieldWrap">
                  <div className="labelWrap">
                    <span className="icon">
                      <svg
                        width="14"
                        height="8"
                        viewBox="0 0 14 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 1L7 7L1 0.999999"
                          stroke="#008755"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <InputLabel text={`${t("Where")}`} />
                  </div>
                  <InputField type={"text"} placeholder={t("Dubai Marina")} />
                </div>
              </Col>
              <Col className="rating">
                <div className="fieldWrap">
                  <div className="labelWrap">
                    <span className="icon">
                      <svg
                        width="14"
                        height="8"
                        viewBox="0 0 14 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 1L7 7L1 0.999999"
                          stroke="#008755"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <InputLabel text="Rating" />
                  </div>
                  <InputField
                    type={"text"}
                    placeholder="3-5"
                    // value={email}
                    // onChange={handleEmailChange}
                  />
                </div>
              </Col>
              <Col className="discount menuEnd">
                <div className="fieldWrap" ref={discountRef}>
                  <div className="labelWrap">
                    <span className="icon">
                      <svg
                        width="14"
                        height="8"
                        viewBox="0 0 14 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 1L7 7L1 0.999999"
                          stroke="#008755"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <InputLabel text="Discount" />
                  </div>
                  <InputField
                    type={"text"}
                    placeholder="20% - 70%"
                    onFocus={() => setDiscountOpen(true)}
                    // value={email}
                    // onChange={handleEmailChange}
                  />
                  {discountOpen && (
                    <DiscountDropDown
                      minValue={minValue}
                      maxValue={maxValue}
                      handleInput={handleInput}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default FilterBar;
