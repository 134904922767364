import { useState, useEffect, useCallback } from "react";
import { appendWordIfNotExists } from "../utils/utilityFunctions";

const useScroll = () => {
  const [scrollClass, setScrollClass] = useState("");

  const [y, setY] = useState(document.scrollingElement.scrollHeight);

  const handleNavigation = useCallback(
    (e) => {
      let classString = "";
      if (y > window.scrollY && window.scrollY > 50) {
        classString.replace("anim-header", "");
      } else if (y < window.scrollY && window.scrollY > 0) {
        classString = appendWordIfNotExists(classString, "anim-header");
      }

      if (window.scrollY > 50) {
        classString = appendWordIfNotExists(classString, "fix-header");
      } else {
        classString.replace("fix-header", "");
      }
      setScrollClass(classString);
      setY(window.scrollY);
    },
    [y]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  //   const scrollToTop = () => {
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth",
  //     });
  //   };

  //   useEffect(() => {
  //     scrollToTop();
  //   }, []);

  return scrollClass;
};

export default useScroll;
