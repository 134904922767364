import { Col, Dropdown, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";

const Pagination = ({
  handlePageChange,
  currentPage,
  getPageCount,
  pageSize,
  handleItemPerPageChange,
  totalRecords,
}) => {
  const { t } = useTranslation();
  return (
    <Row className="mt-3 mt-md-5 flex-md-row-reverse justify-content-between">
      <Col md="8" xs={12} className="flex  ">
        <div className="d-md-flex align-items-center justify-content-end">
          <ReactPaginate
            breakLabel="..."
            nextLabel={t("Next")}
            onClick={(val) => {
              handlePageChange(val?.nextSelectedPage + 1);
            }}
            nextClassName="page-item"
            nextLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            className="pagination mb-md-0 me-3"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            activeClassName="page-item active"
            activeLinkClassName="page-link"
            pageCount={getPageCount()}
            previousLabel={t("Previous")}
            renderOnZeroPageCount={null}
            forcePage={currentPage - 1}
          />
        </div>
      </Col>
      <Col md="4" xs={12} className="">
        <div className="sorting itemsPage d-flex align-items-center">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-sorting">
              {pageSize}
              <span className="icon">
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 1L7 7L1 0.999999"
                    stroke="#008755"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleItemPerPageChange(8)}>
                8
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleItemPerPageChange(12)}>
                12
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleItemPerPageChange(16)}>
                16
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleItemPerPageChange(20)}>
                20
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <span className="title ms-2">
            {t("Showing {{count}} to {{offset}} of {{total}} entries", {
              count: currentPage * pageSize - pageSize + 1,
              offset:
                currentPage * pageSize >= totalRecords
                  ? totalRecords
                  : currentPage * pageSize,
              total: totalRecords,
            })}
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default Pagination;
