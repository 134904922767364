import axios from "axios";
import { resetReduxStore, store } from "../../redux/store";
import { selectToken } from "../../redux/authSlice";
import {
  generateUniqueKey,
  getApiKey,
  getAppSecretKey,
} from "../../utils/utilityFunctions";

const baseURL = process.env.REACT_APP_MOBILE_URL_API;

const axiosInstance = axios.create({
  baseURL,
});
axios.defaults.headers.post["Content-Type"] = "application/json";

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = selectToken(store.getState());
    if (token && config.url !== "login") {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    try {
      if (
        process.env.REACT_APP_ENVIRONMENT !== "DEV" &&
        config?.url !== "/generatekey"
      ) {
        const timestamp = Math.floor(Date.now());
        const appIdentifier = process.env.REACT_APP_APP_IDENTIFIER;
        const osType = process.env.REACT_APP_OS_TYPE;
        const deviceId = generateUniqueKey();
        const deviceInfo = { deviceId, osType };
        const apiKey = await getApiKey(deviceInfo);
        const appSecret = getAppSecretKey(timestamp, deviceId, apiKey);
        config.headers["app_identifier"] = appIdentifier;
        config.headers["req_timestamp"] = timestamp;
        config.headers["deviceId"] = deviceId;
        config.headers["appsecret"] = appSecret;
        config.headers["SRC-Location"] = "777f0lkbc42dF826446bb5b0j4ks";
      }
    } catch (error) {
      console.log(error, "testing");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    if (err?.response?.status == 401 || err?.response?.status == 403) {
      store.dispatch(resetReduxStore());
    }

    return Promise.reject(err);
  }
);

export default axiosInstance;
