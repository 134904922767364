import PageBanner from "../Components/Global/PageBanner";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const TermsAndCondition = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageBanner
        imageUrl="/images/bg-pageBnr.png"
        title={`${t("Terms & Conditions")}`}
      />
      <div className="contentHolder add contentPagecontentPagecontentPage">
        <Container>
          <Row>
            <Col xs={12}>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Introduction")}
                </h2>
                <p>{t("introductionText")}</p>
                <p>{t("introductionText1")}</p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Copyrights")}
                </h2>
                <p>
                  {t("CopyrightsLabel")}
                  <br />
                  {t("copyrightsText")}
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Trademarks")}
                </h2>
                <p>{t("trademarksText")}</p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Possible Errors and Revisions")}
                </h2>
                <p>{t("errorRevisionsText")}</p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Disclaimer of Warranties")}
                </h2>
                <p>{t("disclaimerWarranties")}</p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Disclaimer of Liability")}
                </h2>
                <p>{t("disclaimerLiabilityText")}</p>
                <p>{t("disclaimerLiabilityText1")}</p>
                <p>{t("disclaimerLiabilityText2")}</p>
                <p>{t("disclaimerLiabilityText3")}</p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("No Unlawful or Prohibited Use")}
                </h2>
                <p>{t("prohibitedUseText")}</p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">{t("Privacy")}</h2>
                <p>{t("privacyText")}</p>
                <p>{t("privacyText1")}</p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Password Security")}
                </h2>
                <p>{t("passwordSecurityText")}</p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Virus protection")}
                </h2>
                <p>{t("virusProtectionText")}</p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Governing Law")}
                </h2>
                <p>{t("governingLawText")}</p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Modifications")}
                </h2>
                <p>{t("modificationText")}</p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Settlement of Disputes Relating Electronic Payment")}:
                </h2>
                <p>{t("electronicPaymentText")}</p>
                <p>{t("electronicPaymentText1")}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TermsAndCondition;
