import { useEffect } from "react";
import PageBanner from "../Components/Global/PageBanner";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import i18n from "../i18";
import { useDispatch } from "react-redux";
import { changeLang } from "../redux/configurationSlice";

const HowItWorks = () => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (lang) {
      const defaultLang = lang?.startsWith("ar") ? "ar" : "en";
      i18n.changeLanguage(defaultLang);
      document.documentElement.lang = defaultLang;
      document.documentElement.dir = lang?.startsWith("ar") ? "rtl" : "ltr";
      dispatch(changeLang({ lang: defaultLang }));
    } else {
      const browserLang = "en";
      const defaultLang = browserLang?.startsWith("ar") ? "ar" : "en";
      i18n.changeLanguage(defaultLang);
      document.documentElement.lang = defaultLang;
      document.documentElement.dir = browserLang?.startsWith("ar")
        ? "rtl"
        : "ltr";
      dispatch(changeLang({ lang: defaultLang }));
    }

    return () => {
      document.documentElement.lang = "en";
      document.documentElement.dir = "ltr";
    };
  }, [lang]);

  return (
    <>
      <PageBanner
        imageUrl="/images/bg-pageBnr.png"
        title={`${t("Learn about all esaad offers")}`}
      />
      <div className="contentHolder add contentPage">
        <Container>
          <Row>
            <Col xs={12}>
              <div className="textBlock mb-4">
                <h2 className="fw-700 font-bukra primary-color mb-lg-3">
                  {t("PIN redemption")}
                </h2>
                <p className="fw-500 text-black">{t("How to Redeem Offers")}</p>
                <ul className="numListStyled list-unstyled">
                  <li>
                    <span className="bg-primary rounded-circle secondary-color d-flex align-items-center justify-content-center num">
                      1
                    </span>
                    {t("Find a venue on the app.")}
                  </li>
                  <li>
                    <span className="bg-primary rounded-circle secondary-color d-flex align-items-center justify-content-center num">
                      2
                    </span>
                    {t("Visit them & show your offer when you pay.")}
                  </li>
                  <li>
                    <span className="bg-primary rounded-circle secondary-color d-flex align-items-center justify-content-center num">
                      3
                    </span>
                    {t("They’ll enter a PIN on your app.")}
                  </li>
                  <li>
                    <span className="bg-primary rounded-circle secondary-color d-flex align-items-center justify-content-center num">
                      4
                    </span>
                    {t("Enjoy your saving!")}
                  </li>
                </ul>
              </div>

              <hr />

              <div className="textBlock mb-4 pt-3">
                <h2 className="fw-700 font-bukra primary-color mb-lg-3">
                  {t("Online promocode")}
                </h2>
                <p className="fw-500 text-black">{t("How to Redeem Offers")}</p>
                <ul className="numListStyled list-unstyled">
                  <li>
                    <span className="bg-primary rounded-circle secondary-color d-flex align-items-center justify-content-center num">
                      1
                    </span>
                    {t("Select the merchant which offers online offer")}
                  </li>
                  <li>
                    <span className="bg-primary rounded-circle secondary-color d-flex align-items-center justify-content-center num">
                      2
                    </span>
                    {t("Swipe & redeem the online promocode.")}
                  </li>
                  <li>
                    <span className="bg-primary rounded-circle secondary-color d-flex align-items-center justify-content-center num">
                      3
                    </span>
                    {t("Copy the promocode.")}
                  </li>
                  <li>
                    <span className="bg-primary rounded-circle secondary-color d-flex align-items-center justify-content-center num">
                      4
                    </span>
                    {t(
                      "Go to Partner website and apply the redeemed promocode."
                    )}
                  </li>
                </ul>
              </div>

              <hr />

              <div className="textBlock mb-4 pt-3">
                <h2 className="fw-700 font-bukra primary-color mb-lg-3">
                  {t("Online offers redemption")}
                </h2>
                <p className="fw-500 text-black">{t("How to Redeem Offers")}</p>
                <ul className="numListStyled list-unstyled">
                  <li>
                    <span className="bg-primary rounded-circle secondary-color d-flex align-items-center justify-content-center num">
                      1
                    </span>
                    {t("Select the merchant that offers online offer.")}
                  </li>
                  <li>
                    <span className="bg-primary rounded-circle secondary-color d-flex align-items-center justify-content-center num">
                      2
                    </span>
                    {t(
                      "Tap on the button which takes you to the partnered website."
                    )}
                  </li>
                  <li>
                    <span className="bg-primary rounded-circle secondary-color d-flex align-items-center justify-content-center num">
                      3
                    </span>
                    {t(
                      "Discount offer will be applied in the partnered website."
                    )}
                  </li>
                  <li>
                    <span className="bg-primary rounded-circle secondary-color d-flex align-items-center justify-content-center num">
                      4
                    </span>
                    {t(
                      "Complete the payment on the partnered website to redeem the offer."
                    )}
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HowItWorks;
