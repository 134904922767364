import { useRef, useState } from "react";
import Image from "react-bootstrap/Image";

import { Swiper, SwiperSlide } from "swiper/react";
import {
  FreeMode,
  EffectFade,
  Pagination,
  Thumbs,
  Autoplay,
  Controller,
  Navigation,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/thumbs";
import { selectLangState } from "../../redux/configurationSlice";
import { useSelector } from "react-redux";

const Gallery = ({ gallery, isModal=false, handleShow }) => {
  const { lang } = useSelector(selectLangState);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const isMobile = window.innerWidth <= 768;

  return (
    <div className="gallery">
      <Swiper
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[Autoplay, FreeMode, Thumbs, EffectFade, Pagination, Navigation]}
        pagination={{ type: 'fraction' }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        effect="slide"
        fadeEffect={{ crossFade: true }}
        className="gallery-swiper"
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
        }}
        dir={lang?.startsWith("ar") ? "rtl" : "ltr"}
        key={lang?.startsWith("ar") ? "rtl" : "ltr"}
      >
        {gallery?.map((galleryItem, key) => (
          <SwiperSlide key={key}>
            <Image
              src={galleryItem}
              alt="Background Hero"
              className="d-block img-fluid w-100"
              onClick={handleShow}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      { 
        isModal ? '' : 
        <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        // pagination= {{clickable: true}}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs, Pagination]}
        className="swiper-thumb d-none d-md-block"
        dir={lang?.startsWith("ar") ? "rtl" : "ltr"}
      >
        {gallery?.map((galleryItem, key) => (
          <SwiperSlide key={key}>
            <Image
              src={galleryItem}
              alt="Background Hero"
              className="d-block img-fluid w-100"
            />
          </SwiperSlide>
        ))}
      </Swiper>
      }
    </div>
  );
};

export default Gallery;
