import { useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Recaptcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const verifyCallback = (token) => {
    if (window?.postMessage) {
      window?.postMessage(token);
    }
    if (window?.appInterface != undefined) {
      window?.appInterface?.postMessage(token);
    }
    if (window?.webkit?.messageHandlers?.appInterface != undefined) {
      window?.webkit?.messageHandlers?.appInterface.postMessage(token);
    }
  };

  const captchaLoad = async () => {
    try {
      const token = await executeRecaptcha("register");
      verifyCallback(token);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    executeRecaptcha && captchaLoad();
  }, [executeRecaptcha]);

  return <div className="verify"></div>;
};

export default Recaptcha;
