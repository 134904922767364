import { TEXT_LIMITS } from "../../utils/constants";
import { handlePhoneNumber } from "../../utils/utilityFunctions";
import InputField from "../Form/InputField";

const PhoneNumberField = ({ field, trigger, ...rest }) => {
  const handleChange = async (e) => {
    const { value } = e.target;
    const phoneNumber = handlePhoneNumber(value);
    field.onChange(phoneNumber);
    trigger(field.name);
  };

  return (
    <InputField
      {...rest}
      {...field}
      className="font-dubai text-black text-en-start alignRight"
      placeholder="+971 56 123 1234"
      onChange={handleChange}
      maxLength={
        field?.value?.startsWith("+971")
          ? TEXT_LIMITS.MOBILE
          : field?.value?.startsWith("971")
          ? TEXT_LIMITS.MOBILE - 1
          : TEXT_LIMITS.MOBILE + 1
      }
    />
  );
};

export default PhoneNumberField;
