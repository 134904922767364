import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import Image from "react-bootstrap/Image";
import { useForm } from "react-hook-form";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useState } from "react";
import { EMAIL_REGEX, PHONE_REGEX_WITHOUT_SPACES } from "../../utils/constants";
import { selectLangState } from "../../redux/configurationSlice";
import { AuthAPI } from "../../apis/authApi";
import InputLabel from "../Form/InputLabel";
import InputField from "../Form/InputField";
import FieldValidationText from "../Global/fieldValidationText";

const ForgotPassword = ({ setCurrent, setState, state }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { lang } = useSelector(selectLangState);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({ mode: "all" });
  const onSuccess = (response) => {
    toast.success(t("opt send"));
  };
  const onError = (error) => {
    toast.error(error?.message);
  };
  const {
    mutateAsync: sendOtp,
    status,
    error,
  } = useMutation({
    mutationFn: (data) => AuthAPI.sendOtp(data),
    onSuccess,
    onError,
  });

  const onSubmit = async (values) => {
    try {
      const data = {
        otp_type: "FORGET_PASSWORD",
        company: "ESD",
        platform: "website",
        language: lang?.startsWith("ar") ? "ar" : "en",
      };
      if (EMAIL_REGEX.test(values?.email)) {
        data.email = values?.email;
        await AuthAPI.sendForgotEmail(data);
        toast.success(t("Email send"));
        navigate("/login");
        setCurrent(0);
        setState((prev) => ({
          ...prev,
          email: null,
          mobile_no: null,
        }));
      } else {
        data.mobile_no = values?.email;
        setState((prev) => ({
          ...prev,
          email: null,
          mobile_no: values?.email,
        }));
        await sendOtp(data);
        setCurrent(1);
      }
    } catch (error) {
      toast.error(error?.message);
      console.log(error);
    }
  };

  return (
    <>
      <div className="bg-auth">
        <Image
          src="/images/bg-auth.png"
          alt="icon-uae-pass"
          className="d-inline-block align-middle me-2"
        />
      </div>
      <div className="auth d-flex align-items-center justify-content-center">
        <div className="center-content w-100">
          <h1 className="fw-700 text-white font-bukra">
            {t("Forgot password")}?{" "}
            <span className="d-block fw-400 subTitle font-dubai text-grey-400">
              {t("emailPhoneVerifyText")}.
            </span>
          </h1>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <InputLabel
                className="text-white"
                text={`${t("Email address / Mobile no")}.`}
                htmlFor="emailField"
              />
              <InputField
                className="text-white"
                {...register("email", {
                  required: t("Email is required"),
                  validate: (value) => {
                    if (
                      EMAIL_REGEX.test(value) ||
                      PHONE_REGEX_WITHOUT_SPACES.test(value)
                    ) {
                      return true;
                    }
                    return t("Enter valid email or phone number");
                  },
                })}
                placeholder="e.g, adam@abc.com /+971 XX XXX XXXX"
                id="emailField"
              />
              {errors.email && (
                <FieldValidationText errorMessage={errors.email?.message} />
              )}
            </Form.Group>
            <Button
              className="btn btn-primary fw-500 w-100" //to disable add class "disabled"
              type="submit"
            >
              {t("Next")}
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
