import React from "react";
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
const CustomButton = ({ isLoading, text = "button", ...rest }) => {
  return (
    <Button disabled={isLoading} {...rest}>
      {isLoading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <>{text}</>
      )}
    </Button>
  );
};

export default CustomButton;
