import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

const OfferTypeDropDown = ({
  categoriesFilters,
  setOfferTypeOpen,
  className,
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const offerTypes = searchParams.get("type");
  const [offerType, setOfferType] = useState(offerTypes ?? null);
  const handleSearchResult = (e) => {
    e.stopPropagation();
    const newSearchParams = new URLSearchParams(searchParams);
    if (offerType) {
      newSearchParams.set("type", offerType);
    } else {
      newSearchParams.delete("type");
    }
    setSearchParams(newSearchParams);
    setOfferTypeOpen((prev) => !prev);
  };
  const handleOffer = (e) => {
    e.stopPropagation();
    if (e?.target?.checked) {
      setOfferType(e?.target?.value);
    } else {
      setOfferType(null);
    }
  };
  return (
    <div className={`filterDropdown modal_bottom ${className}`}>
      <span
        className="d-block d-md-none overlay"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setOfferTypeOpen((prev) => !prev);
        }}
      ></span>
      <div className="holder">
        <div className="mb-4 d-flex align-items-center justify-content-between d-md-none">
          <h1 className="fw-700 font-bukra text-black title-lg m-0">
            {t("Offer Type")}
          </h1>
          <Button
            className="closeBtn primary-color text-decoration-none fw-700"
            variant="link"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setOfferTypeOpen((prev) => !prev);
            }}
          >
            {t("Close")}
          </Button>
        </div>
        {categoriesFilters?.filters?.map((subCategory, index) => (
          <div
            className="d-flex align-items-start justify-content-between item"
            key={index}
          >
            <Form.Check
              type="radio"
              id={subCategory?.name}
              label={subCategory?.name}
              name={subCategory?.filter_key}
              value={subCategory?.filter_val}
              checked={offerType == subCategory?.filter_val}
              className="formCustom-radio d-flex align-items-center"
              onChange={handleOffer}
            />
            <span className="count primary-color">
              {subCategory?.offer_count}
            </span>
          </div>
        ))}
        <div className="btn-wrap">
          <Button
            variant="primary"
            className="w-100"
            aria-label="show result button"
            onClick={handleSearchResult}
            disabled={offerType == null}
          >
            {t("Show Results")}
          </Button>
          <Button
            variant="outline"
            className="w-100"
            aria-label="clear all filter button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              searchParams.delete("type");
              setSearchParams(searchParams);
              setOfferTypeOpen((prev) => !prev);
            }}
            disabled={offerType == null}
          >
            {t("Clear Filter")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OfferTypeDropDown;
