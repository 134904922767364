import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const { t } = useTranslation();
  const error = useRouteError();
  const navigate = useNavigate();

  return (
    <div
      id="error-page"
      style={{ height: "100vh" }}
      className=" d-flex flex-column align-items-center justify-content-center"
    >
      <div className="row p-2 m-2  text-black  rounded-2">
        <h1>{t("Oops")}!</h1>
      </div>
      <div className="row">
        <p>{t("unexpectedErrorText")}.</p>
      </div>
      <div className="row pt-3 mt-2 mb-3 bg-warning shadow rounded-2">
        <p>
          <i>
            {error?.error?.message ||
              error?.statusText ||
              t("something went wrong!")}
          </i>
        </p>
      </div>

      <Button onClick={() => navigate(-1)}>{t("Go Back")}</Button>
    </div>
  );
}
