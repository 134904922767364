import PageBanner from "../Components/Global/PageBanner";
import AppDownload from "../Components/Global/AppDownload";
import { Container, Row, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { HomeAPI } from "../apis/homeApi";
import { useSelector } from "react-redux";
import { selectLangState } from "../redux/configurationSlice";
import { useEffect } from "react";
import PageLoader from "../Components/loaders/pageLoader";

const PartnersFacilitiesMobile = () => {
  const { lang } = useSelector(selectLangState);
  const { t } = useTranslation();
  const { data: featuredMerchants, isLoading } = useQuery({
    queryKey: [
      "featuredMerchants",
      {
        company: "ESD",
        location_id: 0,
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
        lat: "25.205200541193324",
        lng: "55.27186452531266",
      },
    ],
    queryFn: ({ queryKey }) => HomeAPI.getFeaturedMerchants({ ...queryKey[1] }),
  });

  return (
    <>
      <div className="contentHolder add prtenerPage" style={{ paddingTop: 0 }}>
        <Container>
          <Row className="cardTypes">
            <Col md={6} xs={12} className="mb-4">
              <div className="item">
                <h2 className="text-uppercase fw-700">
                  {t("SILVER")}
                  <span className="d-block fw-300">{t("Partner")}</span>
                </h2>
                <p className="fw-400">
                  {t("Assigned to companies that")}
                  <br /> {t("offer discount from")}
                </p>
                <p className="dis-ratio font-bukra fw-500">20%-39%</p>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="item gold">
                <h2 className="text-uppercase fw-700">
                  {t("Gold")}
                  <span className="d-block fw-300">{t("Partner")}</span>
                </h2>
                <p className="fw-400">
                  {t("Assigned to companies that")}
                  <br /> {t("offer discount from")}
                </p>
                <p className="dis-ratio font-bukra fw-500">40%-80%</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PartnersFacilitiesMobile;
