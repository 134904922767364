import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { selectLangState } from "../../../redux/configurationSlice";
import { useDispatch, useSelector } from "react-redux";
import { AuthAPI } from "../../../apis/authApi";
import { resetAuth } from "../../../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import ButtonWithLoading from "../../buttons/ButtonWithLoading";
import { MoengageService } from "../../../services/moengage";

const SignoutModal = ({ show, close, ...rest }) => {
  const { lang } = useSelector(selectLangState);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSuccess = (response) => {
    toast.success(response?.message);
    MoengageService.logOutSuccess({});
  };
  const onError = (error) => {
    toast.error(error?.message);
  };
  const {
    mutateAsync: logout,
    status,
    error,
  } = useMutation({
    mutationFn: (data) => AuthAPI.logout(data),
    onSuccess,
    onError,
  });
  const handleLogout = async () => {
    try {
      const data = {
        company: "ESD",
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
      };
      const res = await logout(data);
      dispatch(resetAuth());
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Modal show={show} className="signoutModal" centered {...rest}>
      <Button
        className="closeBtn primary-color text-decoration-none fw-700"
        variant="link"
        onClick={close}
      >
        {t("Close")}
      </Button>
      <Modal.Body className="text-center">
        <>
          <div className="circle-img d-inline-block">
            <svg
              width="102"
              height="102"
              viewBox="0 0 102 102"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="51" cy="51" r="51" fill="#EEEEEE" />
              <g clip-path="url(#clip0_3704_67261)">
                <path
                  d="M68.9413 48.0532L60.6913 39.8032L58.3363 42.1666L65.5029 49.3332H42.0312V52.6666H65.5029L58.3363 59.8332L60.6929 62.1899L68.9429 53.9399C69.3299 53.553 69.637 53.0936 69.8464 52.588C70.0559 52.0824 70.1637 51.5405 70.1637 50.9932C70.1637 50.446 70.0559 49.904 69.8464 49.3985C69.637 48.8929 69.3299 48.4335 68.9429 48.0466L68.9413 48.0532Z"
                  fill="#008755"
                />
                <path
                  d="M48.46 66C48.46 66.442 48.2844 66.866 47.9718 67.1785C47.6593 67.4911 47.2354 67.6667 46.7933 67.6667H36C35.558 67.6667 35.134 67.4911 34.8215 67.1785C34.5089 66.866 34.3333 66.442 34.3333 66V36C34.3333 35.558 34.5089 35.134 34.8215 34.8215C35.134 34.5089 35.558 34.3333 36 34.3333H46.7933C47.2354 34.3333 47.6593 34.5089 47.9718 34.8215C48.2844 35.134 48.46 35.558 48.46 36V44.8883H51.7933V36C51.7933 34.6739 51.2666 33.4021 50.3289 32.4645C49.3912 31.5268 48.1194 31 46.7933 31H36C34.6739 31 33.4021 31.5268 32.4645 32.4645C31.5268 33.4021 31 34.6739 31 36V66C31 67.3261 31.5268 68.5979 32.4645 69.5355C33.4021 70.4732 34.6739 71 36 71H46.7933C48.1194 71 49.3912 70.4732 50.3289 69.5355C51.2666 68.5979 51.7933 67.3261 51.7933 66V57.1117H48.46V66Z"
                  fill="#008755"
                />
              </g>
              <defs>
                <clipPath id="clip0_3704_67261">
                  <rect
                    width="40"
                    height="40"
                    fill="white"
                    transform="translate(31 31)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <h4 className="font-bukra fw-500">{t("Sign out")}</h4>
          <p className="mb-4">{t("Are you sure you want sign out?")}</p>
          <Row>
            <Col md={6} xs={12}>
              <Button
                variant="outline"
                className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                onClick={close}
              >
                {t("No")}
              </Button>
            </Col>
            <Col md={6} xs={12}>
              <ButtonWithLoading
                variant="primary"
                type="submit"
                className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                text={t("Yes")}
                disabled={status == "pending"}
                isLoading={status == "pending"}
                onClick={handleLogout}
              />
            </Col>
          </Row>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default SignoutModal;
