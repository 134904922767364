import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import FaqAccordion from "../Global/FaqAccordion";
import { useTranslation } from "react-i18next";
import { HomeAPI } from "../../apis/homeApi";
import { useQuery } from "@tanstack/react-query";
import { selectLangState } from "../../redux/configurationSlice";
import { useSelector } from "react-redux";

const FaqContent = ({ faqs }) => {
  const { t } = useTranslation();
  const { lang } = useSelector(selectLangState);
  const { data: homeData } = useQuery({
    queryKey: [
      "home",
      {
        company: "ESD",
        location_id: 0,
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
      },
    ],
    queryFn: ({ queryKey }) => HomeAPI.getHomeSections({ ...queryKey[1] }),
  });

  const faqsData = homeData?.sections?.find(
    (section) => section?.section_identifier === "faq_section"
  );

  return (
    <section className="faqContent">
      <Container>
        <Row>
          <Col>
            <h1 className="fw-700 font-bukra text-black title-lg">
              {t("Frequently asked questions")}
            </h1>
            <FaqAccordion data={faqs ?? faqsData} />
            <div className="mt-3 mt-md-4">
              <Link
                to="/faqs"
                className="primary-color text-decoration-none fw-500 link me-4"
              >
                {t("View all FAQs")}
              </Link>
              <Link
                to="/contact"
                className="primary-color text-decoration-none fw-500 link"
              >
                {t("Contact us")}
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FaqContent;
