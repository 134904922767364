import React from "react";
import InputField from "../Form/InputField";
import { Button } from "react-bootstrap";
import InputSlider from "./inputSlider";
import { selectLangState } from "../../redux/configurationSlice";
import { useSelector } from "react-redux";
import { isReversedLang } from "../../utils/utilityFunctions";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DiscountDropDown = ({ setDiscountOpen, className }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const minValue = searchParams.get("min");
  const maxValue = searchParams.get("max");
  const { t } = useTranslation();
  const { lang } = useSelector(selectLangState);
  const [min, setMin] = React.useState(minValue ?? 0);
  const [max, setMax] = React.useState(maxValue ?? 100);

  const clearFilter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("max");
    newSearchParams.delete("min");
    setSearchParams(newSearchParams);
    setDiscountOpen((prev) => !prev);
  };

  const handleSearchResult = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const newSearchParams = new URLSearchParams(searchParams);
    if (min || max) {
      newSearchParams.set("min", min);
      newSearchParams.set("max", max);
    } else {
      newSearchParams.delete("min");
      newSearchParams.delete("max");
    }
    setSearchParams(newSearchParams);
    setDiscountOpen((prev) => !prev);
  };

  const handleChange = (val) => {
    if (val?.length > 1) {
      setMin(val[0]);
      setMax(val[1]);
    }
  };

  return (
    <div className={`filterDropdown modal_bottom ${className}`}>
      <span
        className="d-block d-md-none overlay"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setDiscountOpen((prev) => !prev);
        }}
      ></span>
      <div className="holder">
        <div className="mb-4 d-flex align-items-center justify-content-between d-md-none">
          <h1 className="fw-700 font-bukra text-black title-lg m-0">
            {t("Discount Range")}
          </h1>
          <Button
            className="closeBtn primary-color text-decoration-none fw-700"
            variant="link"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setDiscountOpen((prev) => !prev);
            }}
          >
            {t("Close")}
          </Button>
        </div>
        <div className="d-flex justify-content-between setVal-range">
          <div className="rng-caption-wrap">
            <InputField
              className="dis-field"
              value={min}
              type="number"
              onChange={(e) => {
                if (Number(e.target.value) > 100) {
                  setMin(100);
                } else {
                  setMin(e.target.value);
                }
              }}
            />
          </div>
          <div className="rng-caption-wrap">
            <InputField
              className="dis-field"
              value={max}
              type="number"
              onChange={(e) => {
                if (Number(e.target.value) > 100) {
                  setMax(100);
                } else {
                  setMax(e.target.value);
                }
              }}
            />
          </div>
        </div>
        <InputSlider
          min={0}
          max={100}
          range={true}
          reverse={isReversedLang(lang)}
          onChange={handleChange}
          value={[min, max]}
        />
        <div className="btn-wrap">
          <Button
            variant="primary"
            className="w-100"
            onClick={handleSearchResult}
            disabled={min == null && max == null}
          >
            {t("Show Results")}
          </Button>
          <Button
            variant="outline"
            className="w-100"
            aria-label="clear all filter button"
            onClick={clearFilter}
            disabled={min == null && max == null}
          >
            {t("Clear Filter")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DiscountDropDown;
