import React from "react";

const FieldValidationText = ({
  errorMessage = "field validation error",
  ...rest
}) => {
  return (
    <small className="text-danger" {...rest}>
      {errorMessage}
    </small>
  );
};

export default FieldValidationText;
