import { apiService } from "./configs/axiosConfig";

export const InqueryAPI = {
  getInqueriesType: async () => {
    const response = await apiService({
      url: "http://localhost:3001/inquiries-types",
      method: "GET",
    });

    return response.data;
  },
  getFilteredOffers: async () => {
    const response = await apiService({
      url: "http://localhost:3001/offers/filters",
      method: "GET",
    });

    return response.data;
  },
  getCatWithSubCategories: async () => {
    const response = await apiService({
      url: "http://localhost:3001/subcategories",
      method: "GET",
    });

    return response.data;
  },
  getCountries: async () => {
    try {
      const response = await apiService({
        url: "/countries",
        method: "POST",
        data: {
          company: "ESD",
          language: "en",
        },
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  submitQuery: async (data) => {
    try {
      const response = await apiService({
        url: "/contact/us/submit",
        method: "POST",
        data,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};
