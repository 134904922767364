// import { message } from "antd";
// import { createCustomException } from "../utils/utilityFunctions";
import { CustomException } from "../../utils/utilityFunctions";
import axiosInstance from "./axiosInstance";

export const apiService = async (reqObj, extraProps) => {
  try {
    const res = await axiosInstance.request({
      ...reqObj,
      ...(extraProps?.baseURL && { baseURL: extraProps?.baseURL }),
    });
    // extraProps?.showSuccessToast && message.success(res.data.message);
    return res.data;
  } catch (error) {
    const errObj = new CustomException(
      error?.response?.data?.message || "something went wrong!",
      error?.response?.data?.errorData || []
    );
    // extraProps?.showErrorToast && message.error(errObj?.message);
    return Promise.reject(errObj);
  }
};
