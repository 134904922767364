import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { Col, Container, Row } from "react-bootstrap";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { useTranslation } from "react-i18next";
import { selectLangState } from "../../redux/configurationSlice";
import { useSelector } from "react-redux";

const MainHeroMember = ({ banners }) => {
  const { lang } = useSelector(selectLangState);

  const { t } = useTranslation();
  return (
    <section className="heroMain member">
      <div className="bgImg d-none d-md-block">
        <Image
          src="/images/bg-hero-member.png"
          alt="Background Hero"
          className="d-none d-md-block img-fluid w-100"
        />
      </div>
      <div className="cover">
        <Swiper
          key={lang?.startsWith("ar") ? "rtl" : "ltr"}
          dir={lang?.startsWith("ar") ? "rtl" : "ltr"}
          navigation={{
            prevEl: ".mainHeroSwiper-button-prev",
            nextEl: ".mainHeroSwiper-button-next",
          }}
          modules={[Navigation, Pagination, EffectFade]}
          pagination={{ clickable: true }}
          // effect="fade"
          // fadeEffect={{ crossFade: true }}
          className="mainHero-swiper"
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
          }}
          // noSwipingSelector=".swiper-slide"
        >
          {banners?.map((banner, key) => (
            <SwiperSlide key={key}>
              <Container>
                <Row>
                  <Col md={7} xs={12}>
                    <div className="textHolder">
                      <h1 className="font-bukra fw-700 primary-color">
                        {banner?.title}
                      </h1>
                      <span className="d-block font-bukra primary-color text-lg">
                        {banner?.description}
                      </span>
                      <div className="imgHolder d-block d-md-none">
                        <Image
                          src={
                            banner?.image_url_web ??
                            "/images/esaad-mobileApp-Card.png"
                          }
                          alt={banner?.title}
                          className="d-block img-fluid w-100"
                        />
                      </div>
                      <div className="btn-wrap d-md-flex">
                        <Link
                          className="btn btn-primary fw-700 font-dubai d-block w-md-100"
                          to="/categories"
                        >
                          {t("Explore offers")}
                        </Link>
                      </div>
                    </div>
                  </Col>
                  <Col md={5} xs={12} className="d-none d-md-block">
                    <div className="imgHolder">
                      <Image
                        src={
                          banner?.image_url_web ??
                          "/images/esaad-mobileApp-Card.png"
                        }
                        alt="Esaad mobileApp"
                        className="d-block img-fluid w-100"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </SwiperSlide>
          ))}

          {/* <div className="swiper-button-prev text-black">Back</div>
                <div className="swiper-button-next bg-primary text-white">Next</div> */}
        </Swiper>
      </div>
    </section>
  );
};

export default MainHeroMember;
