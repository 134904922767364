import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Button, Col, Container, Image, Modal, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Gallery from "../Components/Global/Gallery";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { NEWSAPI } from "../apis/newsApi";
import { selectLangState } from "../redux/configurationSlice";
import { useSelector } from "react-redux";
import { stringSliceWithdot } from "../utils/utilityFunctions";
import sanitizeHtml from "sanitize-html";
import { useState } from "react";
import PageLoader from "../Components/loaders/pageLoader";

const StoryDetail = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const param = useParams();
  const { lang } = useSelector(selectLangState);
  const { t } = useTranslation();
  const { data: newsData, isLoading } = useQuery({
    queryKey: [
      "story-detail",
      {
        story_id: param?.id,
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
      },
    ],
    queryFn: ({ queryKey }) => NEWSAPI.getSuccessStory({ ...queryKey[1] }),
  });
  const description = sanitizeHtml(newsData?.description ?? "");

  return isLoading ? (
    <PageLoader />
  ) : (
    <>
      <div className="contentHolder NewsDetail">
        <Container>
          <Row>
            <Col>
              <Breadcrumb className="d-none d-lg-block">
                <Breadcrumb.Item href="/media-center/success-stories">
                  {t("stories")}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {stringSliceWithdot(newsData?.title ?? "")}
                </Breadcrumb.Item>
              </Breadcrumb>
              <div className="d-none d-md-block">
                <h2 className="font-bukra title-lg fw-700 primary-color mb-3">
                  {newsData?.title}
                </h2>
                <time dateTime="" className="dateTime">
                  {newsData?.publish_date}
                </time>
              </div>
            </Col>
          </Row>
          <div className="mb-md-3 overflow-hidden mobile-visible">
            {newsData?.images?.length > 0 && (
              <div className="galleryCol-Wrap">
                <Gallery handleShow={handleShow} gallery={newsData?.images} />
              </div>
            )}
            <div className="textCol-Wrap">
              <div className="textHolder">
                <div className="d-block d-md-none">
                  <h2 className="font-bukra title-lg fw-500 text-black mb-2">
                    {newsData?.title}
                  </h2>
                  <time dateTime="" className="dateTime">
                    {newsData?.publish_date}
                  </time>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                ></div>
              </div>
            </div>
          </div>
          {/* <Row className="d-flex flex-lg-row-reverse mb-0 mb-md-3">
            <Col lg={6} xs={12}>
              <Gallery gallery={newsData?.images_web} />
            </Col>
            <Col lg={6} xs={12}>
              <div className="textHolder">
                <div className="d-block d-md-none">
                  <h2 className="font-bukra title-lg fw-500 text-black mb-2">
                    {newsData?.title}
                  </h2>
                  <time dateTime="" className="dateTime">
                    {newsData?.publish_date}
                  </time>
                </div>
                <p>{newsData?.description}</p>
              </div>
            </Col>
          </Row> */}
          {/* <Row>
            <Col xs={12}>
              <p>{newsData?.description}</p>
            </Col>
          </Row> */}
        </Container>
      </div>
      <Modal
        size="xl"
        centered
        show={show}
        onHide={handleClose}
        className="gallery_Modal"
        animation={false}
        backdropClassName="galleryModal_Backdrop"
      >
        <Modal.Body>
          {/* <button
            type="button"
            className="btn-close btn-closeIcon"
            aria-label="Close"
            onClick={handleClose}
          ></button> */}
          <Button
            className="closeBtn primary-color text-decoration-none fw-700"
            variant="link"
            onClick={handleClose}
          >
            {t("Close")}
          </Button>
          <Gallery gallery={newsData?.images} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StoryDetail;
