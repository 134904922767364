import Accordion from "react-bootstrap/Accordion";

const FaqAccordion = ({ data }) => {
  if (data?.section_items) {
    return (
      <Accordion defaultActiveKey={data?.section_items?.title}>
        {data?.section_items?.map((faq, key) => (
          <Accordion.Item eventKey={faq.title} key={key}>
            <Accordion.Header>{faq?.title}</Accordion.Header>
            <Accordion.Body>{faq?.description}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    );
  }
  return <></>;
};

export default FaqAccordion;
