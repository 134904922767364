import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { MAX_FILE_SIZE } from "../../utils/constants";

const DropZone = ({
  onFileChange,
  acceptedFileType,
  fileSize = 2,
  onDropRejected = (rejectedFiles) => {
    console.log("file rejected", rejectedFiles);
  },
  children,
}) => {
  const { t } = useTranslation();
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    onFileChange(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileType,
    maxFiles: 1, // Optional: restrict to only one file if needed
    multiple: false,
    maxSize: fileSize * 1024 * 1024, // Optional: allow more than one file to be uploaded at a time
    onDropRejected,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export default DropZone;
