import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Image,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InputField from "../../Form/InputField";
import OTPInput from "react-otp-input";
import InputLabel from "../../Form/InputLabel";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { AuthAPI } from "../../../apis/authApi";
import { toast } from "react-toastify";
import ButtonWithLoading from "../../buttons/ButtonWithLoading";
import { useSelector } from "react-redux";
import { selectUserDetails } from "../../../redux/authSlice";
import { selectLangState } from "../../../redux/configurationSlice";
import { stringWithdotinMiddle } from "../../../utils/utilityFunctions";
import FieldValidationText from "../../Global/fieldValidationText";
import { MoengageService } from "../../../services/moengage";

const DeleteModal = ({ show, close, profileRefetch, ...rest }) => {
  const { t } = useTranslation();
  const user = useSelector(selectUserDetails);
  const [deleteResponse, setDeleteResponse] = useState(null);
  const { lang } = useSelector(selectLangState);
  const [timer, setTimer] = useState(40);
  const [isResending, setIsResending] = useState(false);
  const [tab, setTab] = useState(1);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    watch,
  } = useForm({
    mode: "all",
  });
  const handleContinue = () => {
    if (tab == 2 && watch("reason") === "Too many marketing emails") {
      setTab((prev) => prev + 2);
    } else {
      setTab((prev) => prev + 1);
    }
  };

  const onSuccess = (response) => {
    profileRefetch();
    setDeleteResponse(response);
    handleContinue();
    MoengageService.deleteAccountRequest({});
  };
  const onError = (error) => {
    toast.error(error?.message);
  };
  const {
    mutateAsync: deleteAccount,
    status,
    error,
  } = useMutation({
    mutationFn: (data) => AuthAPI.deleteAccount(data),
    onSuccess,
    onError,
  });

  const {
    mutateAsync: sendOtp,
    status: otpStatus,
    error: otpError,
  } = useMutation({
    mutationFn: (data) => AuthAPI.sendOtp(data),
    onSuccess: (response) => {
      if (tab != 4) {
        handleContinue();
      }
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  const {
    mutateAsync: verify,
    status: verifyStatus,
    error: verifyError,
  } = useMutation({
    mutationFn: (data) => AuthAPI.verifyOtp(data),
    onSuccess: (response) => {
      handleContinue();
    },
    onError: (error) => {
      toast.error(error?.message);
    },
  });

  const handleSendOtp = async (data) => {
    try {
      const payload = {
        otp_type: "DELETE_ACCOUNT",
        company: "ESD",
        platform: "website",
        language: lang?.startsWith("ar") ? "ar" : "en",
        email: user?.email,
      };
      await sendOtp(payload);
    } catch (error) {
      console.log(error);
    }
  };

  const verifyOtp = async (data) => {
    try {
      const payload = {
        otp_type: "DELETE_ACCOUNT",
        company: "ESD",
        platform: "website",
        language: lang?.startsWith("ar") ? "ar" : "en",
        email: user?.email,
        otp: Number(data?.otp),
      };
      await verify(payload);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (data) => {
    try {
      const payload = {
        enable_email: data?.enable_email,
      };
      if (data?.reason === "other") {
        payload.reason = data?.message;
      } else {
        payload.reason = data?.reason;
      }
      await deleteAccount(payload);
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendClick = async () => {
    if (!isResending) {
      setIsResending(true);
      try {
        const payload = {
          language: lang?.startsWith("ar") ? "ar" : "en",
          email: user?.email,
          otp_type: "VERIFY_EMAIL",
        };
        await sendOtp(payload);
        setTimer(40);
      } catch (error) {
        console.error("Error during OTP resend:", error);
      } finally {
        setIsResending(false);
      }
    }
  };

  useEffect(() => {
    let intervalId;

    const startTimer = () => {
      intervalId = setInterval(() => {
        if (timer > 0) {
          setTimer((prevTimer) => prevTimer - 1);
        } else {
          clearInterval(intervalId);
        }
      }, 1000);
    };
    if (tab == 4) {
      startTimer();
    }

    return () => clearInterval(intervalId);
  }, [timer, tab]);

  useEffect(() => {
    setTab(1);
    setTimer(40);
    reset();
  }, [show]);

  return (
    <Modal show={show} className="accountModal" centered {...rest}>
      {tab === 2 && (
        <Modal.Header>
          <Modal.Title className="font-bukra fw-500">
            {t("Help us understand why")}
          </Modal.Title>
        </Modal.Header>
      )}
      {tab === 3 && (
        <Modal.Header>
          <Modal.Title className="font-bukra fw-500">
            {t("Unsubscribe from Marketing Emails")}
          </Modal.Title>
        </Modal.Header>
      )}
      {tab == 4 && (
        <Modal.Header>
          <Modal.Title className="font-bukra fw-500">
            {t("Verify your Email")}
          </Modal.Title>
        </Modal.Header>
      )}
      <Button
        className="closeBtn primary-color text-decoration-none fw-700"
        variant="link"
        onClick={close}
      >
        {t("Close")}
      </Button>
      <Modal.Body className="text-center">
        {tab === 1 && (
          <>
            <div className="circle-img d-inline-block">
              <Image
                src="/images/trash-circle.png"
                alt="Trash image"
                fluid
                className="d-block w-100"
              />
            </div>
            <h4 className="font-bukra fw-500">{t("Delete account")}</h4>
            <p className="mb-4">
              {t("Are you sure you want to delete your account")}?
            </p>
            <Row>
              <Col md={6} xs={12}>
                <Button
                  variant="primary"
                  className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                  onClick={close}
                >
                  {t("No, I want to keep saving")}
                </Button>
              </Col>
              <Col md={6} xs={12}>
                <Button
                  variant="outline"
                  className="fw-500 font-dubai d-block w-100"
                  onClick={handleContinue}
                >
                  {t("Yes")}
                </Button>
              </Col>
            </Row>
          </>
        )}
        {tab === 2 && (
          <>
            <p className="mb-4">{t("feedbackText")}</p>
            <ListGroup as="ul" className="resList mb-3">
              <ListGroup.Item as="li">
                <Form.Group controlId="marketing-emails">
                  <Form.Check
                    type="radio"
                    {...register("reason", {
                      required: "Please select a reason",
                    })}
                    value="Too many marketing emails"
                    label={
                      <>
                        <span className="fw-400 d-flex text-start">
                          {t("Too many marketing emails")}{" "}
                        </span>
                      </>
                    }
                    className="form-radio d-flex align-items-center"
                  />
                </Form.Group>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                <Form.Group controlId="app-issue">
                  <Form.Check
                    type="radio"
                    {...register("reason", {
                      required: "Please select a reason",
                    })}
                    value="App is not working as expected"
                    label={
                      <>
                        <span className="fw-400 d-flex text-start">
                          {t("App is not working as expected")}
                        </span>
                      </>
                    }
                    className="form-radio d-flex align-items-center"
                  />
                </Form.Group>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                <Form.Group controlId="not-happy">
                  <Form.Check
                    type="radio"
                    {...register("reason", {
                      required: "Please select a reason",
                    })}
                    value="I’m not happy with entertainer"
                    label={
                      <>
                        <span className="fw-400 d-flex text-start">
                          {t("I’m not happy with entertainer")}
                        </span>
                      </>
                    }
                    className="form-radio d-flex align-items-center"
                  />
                </Form.Group>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                <Form.Group controlId="expensive">
                  <Form.Check
                    type="radio"
                    {...register("reason", {
                      required: "Please select a reason",
                    })}
                    value="Esaad is too expensive"
                    label={
                      <>
                        <span className="fw-400 d-flex text-start">
                          {t("Esaad is too expensive")}
                        </span>
                      </>
                    }
                    className="form-radio d-flex align-items-center"
                  />
                </Form.Group>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                <Form.Group controlId="other-reason">
                  <Form.Check
                    type="radio"
                    {...register("reason", {
                      required: "Please select a reason",
                    })}
                    value="other"
                    label={
                      <>
                        <span className="fw-400 d-flex text-start">
                          {t("Other reason")}
                        </span>
                      </>
                    }
                    className="form-radio d-flex align-items-center"
                  />
                </Form.Group>
                {watch("reason") === "other" && (
                  <>
                    <Form.Group className="mt-3" controlId="message">
                      <InputField
                        className="font-dubai textarea-sm"
                        as="textarea"
                        {...register("message", {
                          required:
                            watch("reason") === "other"
                              ? "Message is required for 'other' reason"
                              : false,
                        })}
                        rows={2}
                        placeholder={`${t("Description")}...`}
                      />
                    </Form.Group>
                    {errors?.message && (
                      <div className="text-start mt-1">
                        <FieldValidationText
                          errorMessage={errors?.message?.message}
                        />
                      </div>
                    )}
                  </>
                )}
                {errors?.reason && (
                  <div className="text-start mt-1">
                    <FieldValidationText
                      errorMessage={errors?.reason?.message}
                    />
                  </div>
                )}
              </ListGroup.Item>
            </ListGroup>
            {watch("reason") === "Too many marketing emails" ? (
              <ButtonWithLoading
                variant="primary"
                type="submit"
                className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                text={t("Continue")}
                isLoading={otpStatus == "pending"}
                onClick={handleSubmit(handleSendOtp)}
              />
            ) : (
              <Button
                variant="primary"
                className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                onClick={handleSubmit(handleContinue)}
              >
                {t("Continue")}
              </Button>
            )}
          </>
        )}
        {tab === 3 && (
          <>
            <p className="mb-4">
              {t(
                "Would you like to keep receiving marketing emails after deleting your account?"
              )}
            </p>
            <ListGroup as="ul" className="resList mb-3">
              <ListGroup.Item as="li">
                <Form.Group controlId="marketing-emails">
                  <Form.Check
                    type="radio"
                    {...register("enable_email", {
                      required: "Please select an option",
                    })}
                    value={0}
                    label={
                      <>
                        <span className="fw-400 d-flex text-start">
                          {t("I don’t want to receive emails")}
                        </span>
                      </>
                    }
                    className="form-radio d-flex align-items-center"
                  />
                </Form.Group>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                <Form.Group controlId="app-issue">
                  <Form.Check
                    type="radio"
                    {...register("enable_email", {
                      required: "Please select an option",
                    })}
                    value={1}
                    label={
                      <>
                        <span className="fw-400 d-flex text-start">
                          {t("I want to receive emails")}
                        </span>
                      </>
                    }
                    className="form-radio d-flex align-items-center"
                  />
                </Form.Group>
                {errors?.enable_email && (
                  <div className=" text-start mt-1">
                    <FieldValidationText
                      errorMessage={errors?.enable_email?.message}
                    />
                  </div>
                )}
              </ListGroup.Item>
            </ListGroup>
            <ButtonWithLoading
              variant="primary"
              type="submit"
              className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
              text={t("Continue")}
              isLoading={otpStatus == "pending"}
              onClick={handleSubmit(handleSendOtp)}
            />
          </>
        )}
        {tab === 4 && (
          <>
            <p className="mb-4 text-start">
              {t(
                `We’ve sent an OTP code to your email ${stringWithdotinMiddle(
                  user?.email
                )}`
              )}
            </p>
            <Form.Group className="mb-3">
              <p className="text-start fw-500 text-black">
                {t("Please enter the 4 digit code")}
              </p>
              <Controller
                name="otp"
                control={control}
                rules={{
                  required: t("OTP is required"),
                  minLength: {
                    value: 4,
                    message: t("Please enter 4 digit code"),
                  },
                }}
                render={({ field }) => (
                  <OTPInput
                    {...field}
                    value={field.value}
                    onChange={field.onChange}
                    numInputs={4}
                    containerStyle={"otpWrap"}
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle="form-control"
                    isInputNum
                    inputType="number"
                  />
                )}
              />
              {errors?.otp && (
                <div className="text-start mt-1">
                  <FieldValidationText errorMessage={errors.otp?.message} />
                </div>
              )}
            </Form.Group>
            <ButtonWithLoading
              variant="primary"
              type="submit"
              className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
              text={t("Verify")}
              isLoading={verifyStatus == "pending"}
              onClick={handleSubmit(verifyOtp)}
            />

            <div className="d-flex align-items-center gap-2 pt-4 fw-400 fs-16">
              <span className="text-black">{t("Code not received yet?")}</span>
              {timer > 0 ? (
                <>
                  <span className="primary-color numberDirection ms-auto">
                    {timer} {t("sec")}{" "}
                  </span>
                </>
              ) : (
                <span className="primary-color">
                  <ButtonWithLoading
                    variant="link"
                    className="primary-color text-decoration-none cursor-pointer"
                    onClick={handleResendClick}
                    isLoading={isResending}
                    aria-disabled={isResending}
                  >
                    {t("Send again")}
                  </ButtonWithLoading>
                </span>
              )}
            </div>
          </>
        )}
        {tab === 5 && (
          <>
            <div className="circle-img d-inline-block">
              <Image
                src="/images/sadface.jpg"
                alt="Trash image"
                fluid
                className="d-block w-100"
              />
            </div>
            <h4 className="font-bukra fw-500">{t("Authentication")}</h4>
            <p className="mb-4">
              {t("We're sorry to hear that you're leaving us.")}?
            </p>
            <Row>
              <Col md={12} xs={12}>
                <ButtonWithLoading
                  variant="primary"
                  type="submit"
                  className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                  text={t("Delete Account")}
                  isLoading={status == "pending"}
                  onClick={handleSubmit(handleDelete)}
                />
              </Col>
            </Row>
          </>
        )}
        {tab === 6 && (
          <>
            <div className="circle-img d-inline-block">
              <Image
                src="/images/tick-img.png"
                alt="Trash image"
                fluid
                className="d-block w-100"
              />
            </div>
            <h4 className="font-bukra fw-500">{deleteResponse?.title}</h4>
            <p className="mb-4">{deleteResponse?.description}?</p>
            <Row>
              <Col md={12} xs={12}>
                <Button
                  variant="primary"
                  className="fw-500 font-dubai d-block w-100 mb-3 mb-md-0"
                  onClick={close}
                >
                  {deleteResponse?.button_text}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
