import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  news: [],
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    resetHome: () => initialState,
  },
  extraReducers: (builder) => {},
});

export const { resetHome } = homeSlice.actions;
export default homeSlice.reducer;
