import { useEffect, useRef } from "react";
import Flatpickr from "react-flatpickr";
import { Arabic } from "flatpickr/dist/l10n/ar";
import { English } from "flatpickr/dist/l10n/default";
import { datePickrDateFormat } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty } from "../../utils/utilityFunctions";
import { selectLangState } from "../../redux/configurationSlice";

const DatePicker = ({
  mode = "single",
  isDisabled = false,
  dateHandler,
  width,
  isForm,
  minDate,
  maxDate,
  loading = false,
  enableTime = false,
  options = {},
  ...rest
}) => {
  const { t } = useTranslation();
  const { lang } = useSelector(selectLangState);
  const fp = useRef();

  useEffect(() => {
    if (fp?.current?.flatpickr) {
      fp.current.flatpickr.input.disabled = loading ? true : false;
    }
  }, [loading]);

  const onChange = (dateObj, dateString) => {
    let [startDateString, endDateString] = dateString.split(` ${t("to")} `);
    if (dateObj.length > 1 && !endDateString) {
      endDateString = startDateString;
    }
    if (startDateString && endDateString) {
      dateHandler(startDateString, endDateString);
    }
  };

  const onFormChange = (dateObj, dateString) => {
    rest.onChange(dateString);
  };

  return (
    <div className="datePicker" style={{ width }}>
      {!isForm &&
      fp?.current?.flatpickr &&
      !isEmpty(fp?.current?.flatpickr?.selectedDates) ? (
        <span
          className="icon input-clear"
          style={{
            pointerEvents: "auto",
            cursor: "pointer",
          }}
          onClick={() => {
            if (!fp?.current?.flatpickr) return;
            fp.current.flatpickr.clear();
            if (!isForm) dateHandler(null, null);
          }}
        >
          <i className="ti ti-x ti-xs"></i>
        </span>
      ) : (
        <span className="icon">
          <i className="ti ti-calendar-event ti-xs"></i>
        </span>
      )}

      <Flatpickr
        {...rest}
        ref={fp}
        style={{ width: "100%" }}
        className="form-control"
        options={{
          ...options,
          mode,
          static: true,
          showMonths: mode === "range" ? 2 : 1,
          enableTime: enableTime,
          dateFormat: rest?.datePickrDateFormat || datePickrDateFormat,
          placeholder: "Date created",
          clickOpens: !isDisabled,
          isDisabled: isDisabled,
          disableMobile: true,
          ...(minDate ? { minDate } : {}),
          ...(maxDate ? { maxDate } : {}),
          locale: lang?.startsWith("ar") ? Arabic : English,
        }}
        onChange={isForm ? onFormChange : onChange}
      />
    </div>
  );
};

export default DatePicker;
