import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import sanitizeHtml from "sanitize-html";

const CardContent = ({ image, date, title, description, link }) => {
  const { t } = useTranslation();
  const descriptionSanitized = sanitizeHtml(description ?? "");

  return (
    <Card className="newsCard h-100">
      <Link to={link ?? "#"} className="primary-color text-decoration-none">
        {image && (
          <div className="imgHolder">
            <Card.Img
              variant="top"
              src={image}
              alt="News Image"
              className="d-block img-fluid w-100"
              onError={(e) => {
                e.target.src = "/images/gallery-img1.png";
              }}
            />
          </div>
        )}
        <Card.Body className="p-md-4">
          {date && <small className="text-muted d-block">{date}</small>}
          <Card.Title className="primary-color font-bukra fw-700 title">
            {title}
          </Card.Title>
          <Card.Text as="div" className="text-body">
            <div
              dangerouslySetInnerHTML={{
                __html: descriptionSanitized,
              }}
            ></div>
          </Card.Text>
          <span className="primary-color text-decoration-none fw-500 read-more">
            {t("Read more")}
          </span>
        </Card.Body>
      </Link>
    </Card>
  );
};

export default CardContent;
