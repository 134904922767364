import { Link, useParams } from "react-router-dom";

import { AuthAPI } from "../../apis/authApi";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { selectLangState } from "../../redux/configurationSlice";

const VerifyEmail = () => {
  const { t } = useTranslation();

  const { token } = useParams();
  const { lang } = useSelector(selectLangState);

  const { data, isLoading } = useQuery({
    queryKey: [
      "verifyEmail",
      {
        company: "ESD",
        location_id: 0,
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
        token: token,
      },
    ],
    queryFn: ({ queryKey }) => AuthAPI.verifyEmailByToken({ ...queryKey[1] }),
    enabled: token !== null,
    retry: false,
  });

  return (
    <>
      <div className="d-flex justify-content-center align-items-center w-100 h-100 flex-column py-5">
        <Link to="/" className="btn btn-primary px-5">
          {t("Home")}
        </Link>
      </div>
    </>
  );
};

export default VerifyEmail;
