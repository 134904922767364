import { handleEmiratesId } from "../../utils/utilityFunctions";
import InputField from "./InputField";

const EmiratesIdField = ({
  field,
  trigger,
  countryCode,
  className = "text-black",
}) => {
  const handleChange = async (e) => {
    const { value } = e.target;
    if (countryCode && value.length < countryCode?.length) return;
    const emirate_id = handleEmiratesId(value);
    field.onChange(emirate_id);
    trigger(emirate_id);
  };

  return (
    <InputField
      {...field}
      className={className}
      placeholder="NNN-NNNN-NNNNNNN-N"
      onChange={handleChange}
      maxLength={18}
    />
  );
};

export default EmiratesIdField;
