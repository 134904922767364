import { useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import Flatpickr from "react-flatpickr";
import { Arabic } from "flatpickr/dist/l10n/ar";
import { English } from "flatpickr/dist/l10n/default";
import { useQuery } from "@tanstack/react-query";
import PageBanner from "../Components/Global/PageBanner";
import InputField from "../Components/Form/InputField";
import CardContent from "../Components/Global/CardContent";
import { selectLangState } from "../redux/configurationSlice";
import { useSelector } from "react-redux";
import { formatDate } from "../utils/utilityFunctions";
import { useDebounce } from "use-debounce";
import { NEWSAPI } from "../apis/newsApi";
import { datePickrDateFormat } from "../utils/constants";
import Pagination from "../Components/Global/Pagination";
import PageLoader from "../Components/loaders/pageLoader";

const SuccessStories = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const [endtDate, setEndDate] = useState(null);
  const { lang } = useSelector(selectLangState);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);

  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const { data: newsData, isLoading } = useQuery({
    queryKey: [
      "allstories",
      {
        query_str: debouncedSearchTerm,
        // start_date: startDate ? formatDate(startDate) : null,
        // end_date: endtDate ? formatDate(endtDate) : null,
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
        limit: pageSize,
        offset: (currentPage - 1) * pageSize,
      },
    ],
    queryFn: ({ queryKey }) => NEWSAPI.getSuccessStories({ ...queryKey[1] }),
  });

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleItemPerPageChange = (itemPerPage) => {
    setPageSize(itemPerPage);
  };

  const getPageCount = () => {
    return Math.ceil((newsData?.total_stories ?? 0) / pageSize);
  };

  const preventDefault = (e) => {
    e.preventDefault();
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };
  const reset = (e) => {
    e.preventDefault();
    setSearchTerm("");
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <>
      <PageBanner imageUrl="/images/bg-pageBnr.png" title={newsData?.title} />
      <div className="contentHolder add">
        <Container>
          <Form onSubmit={preventDefault}>
            <Row className="mb-4">
              <Col md={4} xs={12}>
                <div className="searchDefaul">
                  <Form>
                    <InputField
                      type={"search"}
                      placeholder={t("Search")}
                      value={searchTerm}
                      onChange={handleInputChange}
                      className="search"
                      onKeyDown={onKeyDown}
                    />
                    <span className="searchBtn">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8.62474 14.5319C5.36403 14.5319 2.71403 11.8819 2.71403 8.62474C2.71403 5.3676 5.36403 2.71403 8.62474 2.71403C11.8819 2.71403 14.5355 5.3676 14.5355 8.62474C14.5355 11.8819 11.8819 14.5319 8.62474 14.5319ZM18.5357 17.5071L14.3286 13.3C15.3714 12.0285 16 10.4 16 8.625C16 4.55357 12.6964 1.25 8.625 1.25C4.55 1.25 1.25 4.55357 1.25 8.625C1.25 12.6964 4.55 16 8.625 16C10.4 16 12.025 15.375 13.2964 14.3321L17.5036 18.5357C17.7893 18.8214 18.25 18.8214 18.5357 18.5357C18.8214 18.2536 18.8214 17.7893 18.5357 17.5071Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </Form>
                </div>
              </Col>
              {/* 
              <Col md={8} xs={12}>
                <div className="d-md-flex justify-content-lg-end datepickerWrap">
                  <div className="pickerWrap">
                    <span className="icon">
                      <svg
                        width="20"
                        height="22"
                        viewBox="0 0 20 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15 1C15 0.447715 14.5523 0 14 0C13.4477 0 13 0.447715 13 1V2H7V1C7 0.447715 6.55229 0 6 0C5.44772 0 5 0.447715 5 1V2H3C1.34315 2 0 3.34315 0 5V19C0 20.6569 1.34315 22 3 22H17C18.6569 22 20 20.6569 20 19V5C20 3.34315 18.6569 2 17 2H15V1ZM18 8V5C18 4.44772 17.5523 4 17 4H15V5C15 5.55228 14.5523 6 14 6C13.4477 6 13 5.55228 13 5V4H7V5C7 5.55228 6.55229 6 6 6C5.44772 6 5 5.55228 5 5V4H3C2.44772 4 2 4.44772 2 5V8H18ZM2 10H18V19C18 19.5523 17.5523 20 17 20H3C2.44772 20 2 19.5523 2 19V10Z"
                          fill="#008755"
                        />
                      </svg>
                    </span>

                    <Flatpickr
                      value={startDate}
                      className="form-control w-100"
                      placeholder={t("Start Date")}
                      options={{
                        mode: "single",
                        enableTime: false,
                        dateFormat: datePickrDateFormat,
                        locale: lang?.startsWith("ar") ? Arabic : English,
                        disableMobile: "true",
                        // maxDate: endtDate,
                      }}
                      onChange={(date) => {
                        setStartDate(date?.[0] ?? null);
                      }}
                    />
                  </div>
                  <div className="pickerWrap ms-md-3">
                    <span className="icon">
                      <svg
                        width="20"
                        height="22"
                        viewBox="0 0 20 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15 1C15 0.447715 14.5523 0 14 0C13.4477 0 13 0.447715 13 1V2H7V1C7 0.447715 6.55229 0 6 0C5.44772 0 5 0.447715 5 1V2H3C1.34315 2 0 3.34315 0 5V19C0 20.6569 1.34315 22 3 22H17C18.6569 22 20 20.6569 20 19V5C20 3.34315 18.6569 2 17 2H15V1ZM18 8V5C18 4.44772 17.5523 4 17 4H15V5C15 5.55228 14.5523 6 14 6C13.4477 6 13 5.55228 13 5V4H7V5C7 5.55228 6.55229 6 6 6C5.44772 6 5 5.55228 5 5V4H3C2.44772 4 2 4.44772 2 5V8H18ZM2 10H18V19C18 19.5523 17.5523 20 17 20H3C2.44772 20 2 19.5523 2 19V10Z"
                          fill="#008755"
                        />
                      </svg>
                    </span>
                    <Flatpickr
                      value={endtDate}
                      className="form-control w-100"
                      placeholder={t("End Date")}
                      options={{
                        mode: "single",
                        enableTime: false,
                        dateFormat: datePickrDateFormat,
                        minDate: startDate,
                        locale: lang?.startsWith("ar") ? Arabic : English,
                        disableMobile: "true",
                      }}
                      onChange={(date) => {
                        setEndDate(date?.[0] ?? null);
                      }}
                    />
                  </div> 
                  <Button
                    variant="primary"
                    className="fw-500 ms-md-3"
                    onClick={reset}
                  >
                    {t("Reset")}
                  </Button>
                </div>
              </Col>
                  */}
            </Row>
          </Form>
          <Row className="g-4">
            {isLoading ? (
              <PageLoader />
            ) : (
              newsData?.news?.map((news, key) => (
                <Col xl={4} md={6} xs={12} key={key}>
                  <CardContent
                    image={news?.image_url}
                    date={news?.publish_date}
                    title={news?.title}
                    description={news?.description}
                    link={`/media-center/success-stories/${news?.id}`}
                  />
                </Col>
              ))
            )}
          </Row>
          {getPageCount() > 1 && (
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              getPageCount={getPageCount}
              pageSize={pageSize}
              totalRecords={newsData?.total_stories}
              handleItemPerPageChange={handleItemPerPageChange}
            />
          )}
        </Container>
      </div>
    </>
  );
};

export default SuccessStories;
