import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import InputLabel from "../Form/InputLabel";
import CategoryDropDown from "../Global/CategoryDropDown";
import useOutside from "../../hooks/useOutside";
import DiscountDropDown from "../Global/DiscountDropDown";
import { useTranslation } from "react-i18next";
import OfferTypeDropDown from "../Global/OfferTypeDropDown";
import RatingDropDown from "../Global/RatingDropDown";

const CategoryFilter = ({ filters }) => {
  const { t } = useTranslation();
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [offerTypeOpen, setOfferTypeOpen] = useState(false);
  const [discountOpen, setDiscountOpen] = useState(false);
  const [ratingOpen, setRatingOpen] = useState(false);
  const ref = useOutside(() => setCategoryOpen(false));
  const offerRef = useOutside(() => setOfferTypeOpen(false));
  const discountRef = useOutside(() => setDiscountOpen(false));
  const ratingRef = useOutside(() => setRatingOpen(false));

  return (
    <div className="filterBar category">
      <div className="filterBar-wrap">
        <Row className="g-0 mbScroll">
          <Col className="category">
            <div
              className="fieldWrap"
              ref={ref}
              onClick={() => setCategoryOpen(true)}
            >
              <div className="labelWrap">
                <span className="icon">
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 1L7 7L1 0.999999"
                      stroke="#008755"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <InputLabel text={t("Sub Categories")} />
              </div>
              {categoryOpen && (
                <CategoryDropDown
                  className={categoryOpen ? "show" : ""}
                  categoriesFilters={filters?.find(
                    (filter) => filter?.id == "sub_categories"
                  )}
                  setCategoryOpen={setCategoryOpen}
                />
              )}
            </div>
          </Col>
          <Col className="mallLocation">
            <div
              className="fieldWrap"
              ref={offerRef}
              onClick={() => setOfferTypeOpen(true)}
            >
              <div className="labelWrap">
                <span className="icon">
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 1L7 7L1 0.999999"
                      stroke="#008755"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <InputLabel text={t("Offer Type")} />
              </div>
              {offerTypeOpen && (
                <OfferTypeDropDown
                  className={offerTypeOpen ? "show" : ""}
                  categoriesFilters={filters?.find(
                    (filter) => filter?.id == "offer_type"
                  )}
                  setOfferTypeOpen={setOfferTypeOpen}
                />
              )}
            </div>
          </Col>
          <Col className="discount">
            <div
              className="fieldWrap"
              ref={discountRef}
              onClick={() => setDiscountOpen(true)}
            >
              <div className="labelWrap">
                <span className="icon">
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 1L7 7L1 0.999999"
                      stroke="#008755"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <InputLabel text={`${t("Discount Range")}`} />
              </div>
              {discountOpen && (
                <DiscountDropDown
                  className={discountOpen ? "show" : ""}
                  setDiscountOpen={setDiscountOpen}
                />
              )}
            </div>
          </Col>
          {/* <Col className="mallLocation">
            <div className="fieldWrap">
              <div className="labelWrap">
                <span className="icon">
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 1L7 7L1 0.999999"
                      stroke="#008755"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <InputLabel text={`${t("LocationMall")}`} />
              </div>
            </div>
          </Col> */}
          {/* <Col className="rating menuEnd">
            <div
              className="fieldWrap"
              ref={ratingRef}
              onClick={() => setRatingOpen(true)}
            >
              <div className="labelWrap">
                <span className="icon">
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 1L7 7L1 0.999999"
                      stroke="#008755"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <InputLabel text={`${t("Rating")}`} />
              </div>
              {ratingOpen && (
                <RatingDropDown
                  className={ratingOpen ? "show" : ""}
                  setRatingOpen={setRatingOpen}
                />
              )}
            </div>
          </Col> */}
        </Row>
      </div>
    </div>
  );
};

export default CategoryFilter;
