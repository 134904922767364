import React, { useCallback, useEffect, useState } from "react";
import SearchBody from "./searchBody";
import SearchHeader from "./searchHeader";
import useOutside from "../../../hooks/useOutside";
import { SearchAPI } from "../../../apis/searchApi";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { selectLangState } from "../../../redux/configurationSlice";
import { addSearch, selectHistory } from "../../../redux/searchSlice";
import { useLocation, useNavigate } from "react-router-dom";

const Search = () => {
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const history = useSelector(selectHistory);
  const { pathname } = useLocation() || {};
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState({
    outlets: [],
    categories: [],
    pagination: null,
  });
  const { lang } = useSelector(selectLangState);
  const dispatch = useDispatch();
  const [trendsHistory, setTrendsHistory] = useState({
    trends: [],
    history: [],
  });
  const onFocusHandle = async () => {
    try {
      setLoading(true);
      const payload = {
        company: "ESD",
        langugae: lang?.startsWith("ar") ? "ar" : "en",
      };
      const res = await SearchAPI.getTrendsandHistory(payload);
      setTrendsHistory((prev) => ({
        ...prev,
        trends: res?.trending_searched_keywords ?? [],
      }));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleSearchFocus = () => {
    setIsSearchFocused(true);
  };

  const handleSearchBlur = () => {
    setIsSearchFocused(false);
    setSearchText("");
  };
  const ref = useOutside(handleSearchBlur);

  console.log("trendsHistory", trendsHistory);

  const debouncedSearch = useCallback(
    debounce(async (term) => {
      try {
        setLoading(true);
        if (term?.length > 0) {
          dispatch(addSearch(term));
          const payload = {
            language: lang?.startsWith("ar") ? "ar" : "en",
            query_str: term,
            limit: 10,
          };
          const res = await SearchAPI.search(payload);
          setSearchResults((prev) => ({
            ...prev,
            outlets: res?.outlets ?? [],
            categories: res?.categories_section ?? [],
            pagination: res?.pagination ?? null,
          }));
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }, 500),
    []
  );

  const handleInputChange = (value) => {
    setSearchText(value);
    debouncedSearch(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      navigate(`/search/${searchText}`);
      handleSearchBlur();
    }
  };

  useEffect(() => {
    setTrendsHistory((prev) => ({
      ...prev,
      history: history,
    }));
  }, [history]);

  // Close search modal when route changes
  useEffect(() => {
    if (pathname) handleSearchBlur();
  }, [pathname]);

  return (
    <div
      onClick={handleSearchFocus}
      className={`header-search d-none d-xl-block ${
        isSearchFocused ? "focus" : ""
      }`}
      ref={ref}
    >
      <span className="search-icon">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.1986 12.608H13.3645L13.0688 12.3231C14.3358 10.8458 14.9905 8.83037 14.6315 6.68827C14.1352 3.75477 11.6857 1.41219 8.72935 1.05342C4.26319 0.504709 0.504417 4.26127 1.05345 8.72484C1.41244 11.6794 3.75639 14.1275 6.69162 14.6235C8.83494 14.9823 10.8516 14.328 12.3298 13.0618L12.6148 13.3572V14.1909L17.1021 18.6755C17.535 19.1082 18.2424 19.1082 18.6753 18.6755C19.1082 18.2429 19.1082 17.5359 18.6753 17.1033L14.1986 12.608ZM7.8636 12.608C5.23457 12.608 3.11233 10.4871 3.11233 7.85957C3.11233 5.23207 5.23457 3.11109 7.8636 3.11109C10.4926 3.11109 12.6148 5.23207 12.6148 7.85957C12.6148 10.4871 10.4926 12.608 7.8636 12.608Z"
            fill="black"
          />
        </svg>
      </span>
      <SearchHeader
        onFocus={onFocusHandle}
        onChange={(e) => handleInputChange(e.target.value)}
        value={searchText}
        onKeyDown={handleKeyDown}
      />
      {isSearchFocused && (
        <div className="dropdown-menu search-dropdownMenu d-block">
          <SearchBody
            trendsHistory={trendsHistory}
            searchResults={searchResults}
            searchText={searchText}
            setSearchText={handleInputChange}
            setIsSearchFocused={setIsSearchFocused}
            loading={loading}
          />
        </div>
      )}
    </div>
  );
};

export default Search;
