import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { MAX_FILE_SIZE } from "../../utils/constants";

const FileUpload = ({
  title,
  onFileChange,
  acceptedFileType,
  type = "img",
}) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    onFileChange(file);
    setSelectedFile(file);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileType,
    maxFiles: 1, // Optional: restrict to only one file if needed
    multiple: false,
    maxSize: MAX_FILE_SIZE, // Optional: allow more than one file to be uploaded at a time
    onDropRejected: (rejectedFiles) => {
      rejectedFiles.forEach((file) => {
        console.error(file.errors);
        if (file.errors[0].code == "file-invalid-type") {
          console.error(file.errors[0].message);
          toast.error(t("Invalid file type"));
        } else if (file.errors[0].code == "file-too-large") {
          console.error(file.errors[0].message);
          toast.error(t("File size is greater than 2MB"));
        }
        // "file-too-large"
      });
    },
  });

  return (
    <div
      {...getRootProps()}
      className="dropzone d-flex align-items-center justify-content-center flex-column"
    >
      <input {...getInputProps()} />
      <div className="imgHolder">
        {selectedFile && type === "img" ? (
          <Image src={URL.createObjectURL(selectedFile)} alt="Selected Image" />
        ) : (
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="48" height="48" rx="6" fill="#EEEEEE" />
            <path
              d="M14.6641 29.8333V32.1666C14.6641 33.4552 15.7087 34.4999 16.9974 34.4999H30.9974C32.2861 34.4999 33.3307 33.4552 33.3307 32.1666V29.8333"
              stroke="#008755"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M18.1641 20.5001L23.9974 14.6667L29.8307 20.5001"
              stroke="#008755"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.9974 14.6667V28.6667"
              stroke="#008755"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
      <h3
        style={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
        className="w-100 fw-500 font-bukra text-black"
      >
        {selectedFile ? selectedFile.name : <span>{title}</span>}
      </h3>
      <p>
        {selectedFile ? (
          <span className="fw-500 primary-color">
            {selectedFile && type === "img"
              ? t("Update logo")
              : t("Update document")}
          </span>
        ) : type === "img" ? (
          `JPG, JPEG PNG ${t("less than 2MB")}`
        ) : (
          `PDF, JPG, JPEG PNG ${t("less than 2MB")}`
        )}
      </p>
    </div>
  );
};

export default FileUpload;
