import React from "react";
import InputField from "../../Form/InputField";
import { useTranslation } from "react-i18next";

const SearchHeader = (props) => {
  const { t } = useTranslation();
  return (
    <InputField
      type={"search"}
      placeholder={t("Search...")}
      className="search"
      id="hd-search"
      {...props}
    />
  );
};

export default SearchHeader;
