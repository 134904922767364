import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import CardContent from "../Global/CardContent";
import { useTranslation } from "react-i18next";
import { selectLangState } from "../../redux/configurationSlice";
import { useSelector } from "react-redux";

const HomeNews = ({ news, title }) => {
  const { t } = useTranslation();
  const { lang } = useSelector(selectLangState);

  return (
    <section className="main-news">
      <Container>
        <Row className="head g-0">
          <Col xs={7}>
            <h2 className="fw-700 font-bukra text-black title-lg">{title}</h2>
          </Col>
          <Col xs={5} className="d-flex justify-content-end">
            <div>
              <Link
                to="/media-center/news"
                className="primary-color text-decoration-none fw-500 link btn btn-outline"
              >
                {t("Show All")}
              </Link>
            </div>
          </Col>
          <Col xs={12} className="d-none d-md-block">
            <small className="fw-300 text-black text-info">
              {t("Stay informed with the most recent news!")}
            </small>
          </Col>
        </Row>
        <Row xs={1} className="g-4">
          <Col>
            <div className="position-relative">
              <Swiper
                key={lang?.startsWith("ar") ? "rtl" : "ltr"}
                dir={lang?.startsWith("ar") ? "rtl" : "ltr"}
                navigation={{
                  prevEl: ".swiper-news-prev",
                  nextEl: ".swiper-news-next",
                }}
                keyboard={{
                  enabled: true,
                }}
                modules={[Navigation, Keyboard]}
                className="mainNews"
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 12,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 24,
                  },
                }}
              >
                {news?.map((news) => (
                  <SwiperSlide key={news?.id}>
                    <CardContent
                      image={news?.image_url}
                      date={news?.publish_date}
                      title={news?.title}
                      description={news?.description}
                      link={`/media-center/news/${news?.id}`}
                    />
                  </SwiperSlide>
                ))}
                <div className="swiper-button-prev swiper-news-prev"></div>
                <div className="swiper-button-next swiper-news-next"></div>
              </Swiper>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HomeNews;
