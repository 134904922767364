import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import english from "./locale/en.json";
import arabic from "./locale/ar.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: english,
    },
    ar: {
      translation: arabic,
    },
  },
  // lng: "en", // if you're using a language detector, do not define the lng option
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
