import moengage from "@moengage/web-sdk/dist/index";
import { Moengage } from "../utils/constants";

export const MoengageService = {
  init: () => {
    moengage.initialize({
      app_id: "KKGQJBJ76OVHBZEVSBVOE817",
      debug_logs: process.env.NODE_ENV === "development" ? 1 : 0,
      data_center: "dc_2",
    });
  },
  loginSuccess: (data) => {
    moengage.track_event(Moengage.EventName.LOGIN_SUCCESS, data);
  },
  loginFailure: (data) => {
    moengage.track_event(Moengage.EventName.LOGIN_FAILURE, data);
  },
  registrationSuccess: (data) => {
    moengage.track_event(Moengage.EventName.REGISTRATION_SUCCESS, data);
  },
  logOutSuccess: (data) => {
    moengage.track_event(Moengage.EventName.LOG_OUT_SUCCESS, data);
  },
  deleteAccountRequest: (data) => {
    moengage.track_event(Moengage.EventName.DELETE_ACCOUNT_REQUEST, data);
  },
  profileUpdateSuccess: (data) => {
    moengage.track_event(Moengage.EventName.PROFILE_UPDATE_SUCCESS, data);
  },
  homeScreenView: (data = {}) => {
    moengage.track_event(Moengage.EventName.HOME_SCREEN_TILE_CLICK, data);
  },
  homeScreenTileClick: (data) => {
    moengage.track_event(Moengage.EventName.HOME_SCREEN_TILE_CLICK, data);
  },
  tileClick: (data) => {
    moengage.track_event(Moengage.EventName.TILE_CLICK, data);
  },
  menuHomeClick: (data) => {
    moengage.track_event(Moengage.EventName.MENU_HOME_CLICK, data);
  },
  menuAccountClick: (data) => {
    moengage.track_event(Moengage.EventName.MENU_ACCOUNT_CLICK, data);
  },
  searchClick: (data) => {
    moengage.track_event(Moengage.EventName.SEARCH_CLICK, data);
  },
  merchantClick: (data) => {
    moengage.track_event(Moengage.EventName.MERCHANT_CLICK, data);
  },
  merchantDetailView: (data) => {
    moengage.track_event(Moengage.EventName.MERCHANT_DETAIL_VIEW, data);
  },
  merchantPinEnter: (data) => {
    moengage.track_event(Moengage.EventName.MERCHANT_PIN_ENTER, data);
  },
  merchantDetailOfferClick: (data) => {
    moengage.track_event(Moengage.EventName.MERCHANT_DETAIL_OFFER_CLICK, data);
  },
  offerRedemptionSuccess: (data) => {
    moengage.track_event(Moengage.EventName.OFFER_REDEMPTION_SUCCESS, data);
  },
  offerRedemptionFailure: (data) => {
    moengage.track_event(Moengage.EventName.OFFER_REDEMPTION_FAILURE, data);
  },
  addToFavourite: (data) => {
    moengage.track_event(Moengage.EventName.ADD_TO_FAVOURITE, data);
  },
  removeFromFavourite: (data) => {
    moengage.track_event(Moengage.EventName.REMOVE_FROM_FAVOURITE, data);
  },
  userProfileDetails: (data) => {
    moengage.track_event(Moengage.EventName.USER_PROFILE_DETAILS, data);
  },
  visitHomePage: () => {
    moengage.track_event(Moengage.EventName.HOME_SCREEN_VIEW, {
      screen: Moengage.ScreenName.HOME,
    });
  },
  visitCategoriesPage: () => {
    moengage.track_event(Moengage.EventName.HOME_SCREEN_VIEW, {
      screen: Moengage.ScreenName.CATEGORIES,
    });
  },
  visitOutletListingPage: () => {
    moengage.track_event(Moengage.EventName.HOME_SCREEN_VIEW, {
      screen: Moengage.ScreenName.OUTLET_LISTING,
    });
  },
  visitMerchantDetailPage: () => {
    moengage.track_event(Moengage.EventName.HOME_SCREEN_VIEW, {
      screen: Moengage.ScreenName.MERCHANT_DETAIL,
    });
  },

  visitFavouritePage: () => {
    moengage.track_event(Moengage.EventName.HOME_SCREEN_VIEW, {
      screen: Moengage.ScreenName.FAVOURITES,
    });
  },
};
