import PageBanner from "../Components/Global/PageBanner";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageBanner
        imageUrl="/images/bg-pageBnr.png"
        title={`${t("Privacy Policy")}`}
      />
      <div className="contentHolder add contentPage">
        <Container>
          <Row>
            <Col xs={12}>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Overview:")}
                </h2>
                <p>
                  {t("overViewText")}{" "}
                  <Link
                    href="esaad.dubaipolice.gov.ae"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    esaad.dubaipolice.gov.ae
                  </Link>
                </p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Policy Statement:")}
                </h2>
                <p>{t("policyStatement1")}.</p>
                <p>{t("policyStatement2")}</p>
                <p>{t("policyStatement3")}</p>
                <p>{t("policyStatement4")}</p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Policy Scope:")}
                </h2>
                <p>{t("policyScopeText")}</p>
                <p>{t("policyScopeText1")}</p>
                <p>{t("policyScopeText2")}</p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Collection and Storage of Information Automatically")}
                </h2>
                <p>{t("storageInfoText1")}</p>
                <p>{t("storageInfoText2")}</p>
                <p>{t("storageInfoText3")}</p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Protection of personal information:")}
                </h2>
                <p>{t("personalInfoText")}</p>
                <p>{t("personalInfoText1")}</p>
                <p>{t("personalInfoText2")}</p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Information Security")}
                </h2>
                <p>{t("securityInfoText")}</p>
                <p>{t("securityInfoText1")}</p>
                <p>{t("securityInfoText2")}</p>
              </div>
              <div className="textBlock">
                <h2 className="fw-700 font-bukra text-black">
                  {t("Amendment of Privacy Policy:")}
                </h2>
                <p>{t("amendmentPolicyText")}</p>
              </div>
              <div className="textBlock">
                <p>
                  <span className="fw-500">{t("Contact us:")}</span> <br />
                  {t("contactUsText")}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PrivacyPolicy;
