import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  history: [],
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    resetSearch: () => initialState,
    addSearch: (state, action) => {
      // To avoid duplicate items
      if (!state.history.includes(action.payload)) {
        const newHistory = [action.payload, ...state?.history?.slice(0, 4)];
        state.history = newHistory;
      }
    },
  },
});

export const selectHistory = (state) => state.search.history;

export const { resetSearch, addSearch } = searchSlice.actions;
export default searchSlice.reducer;
