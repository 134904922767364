import { featuredBrands } from "../utils/constants";
import { apiService } from "./configs/axiosConfig";
const webURL = process.env.REACT_APP_WEB_URL_API;
const gateWayURL = process.env.REACT_APP_GATEWAY_URL;

export const HomeAPI = {
  getLocations: async () => {
    const response = await apiService({
      url: "https://data.police.uk/api/crimes-street/all-crime?lat=52.629729&lng=-1.131592&date=2023-01",
      method: "GET",
    });

    return response.data;
  },
  getOffers: async () => {
    const response = await apiService({
      url: "http://localhost:3001/offers",
      method: "GET",
    });
    return response.data;
  },
  getNewOffers: async () => {
    const response = await apiService({
      url: "http://localhost:3001/offers",
      method: "GET",
    });
    return response.data;
  },
  getFeaturedBrands: async () => {
    const response = featuredBrands;
    return response;
  },
  getFaqs: async () => {
    const response = await apiService({
      url: "http://localhost:3001/faqs",
      method: "GET",
    });
    return response.data;
  },
  getCategories: async (data) => {
    const response = await apiService({
      url: "/categories",
      method: "POST",
      data: { ...data },
    });
    return response.data;
  },
  getSectionItems: async (data) => {
    const response = await apiService({
      url: "/section/items",
      method: "POST",
      data: { ...data },
    });
    return response.data;
  },
  getOutlets: async (data) => {
    const response = await apiService({
      url: "/outlets",
      method: "POST",
      data: { ...data },
    });
    return response.data;
  },
  getHomeSections: async (data) => {
    try {
      const response = await apiService({
        url: "/home",
        method: "POST",
        data: { ...data },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getFeedback: async (data) => {
    try {
      const response = await apiService({
        url: "/happiness/meter",
        method: "POST",
        data: { ...data },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  submitFeedback: async (data) => {
    try {
      const response = await apiService({
        url: "/happiness/meter/add",
        method: "POST",
        data: { ...data },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getConfiguration: async (data) => {
    const response = await apiService({
      url: "/configs",
      method: "POST",
      data,
    });

    return response.data;
  },
  getConfig: async (data) => {
    try {
      const response = await apiService(
        {
          url:
            process.env.REACT_APP_ENVIRONMENT == "DEV"
              ? "/configs"
              : `/webconfigs`,
          method: "POST",
          data,
        },
        { baseURL: webURL }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  registerCompany: async (data) => {
    try {
      const response = await apiService(
        {
          url: `/merchant/register`,
          method: "POST",
          data,
        },
        { baseURL: webURL }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getMerchantDetail: async (id, data) => {
    try {
      const response = await apiService({
        url: `/merchants/${id}`,
        method: "POST",
        data,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getMerchantRatings: async (data) => {
    try {
      const response = await apiService({
        url: `/merchant/ratings`,
        method: "POST",
        data,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  merchantFeedback: async (data) => {
    try {
      const response = await apiService({
        url: `/merchant/feedback/submit`,
        method: "POST",
        data,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  visit: async (data) => {
    try {
      const response = await apiService(
        {
          url: `/visit`,
          method: "POST",
          data,
        },
        { baseURL: webURL }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getFeaturedMerchants: async (data) => {
    try {
      const response = await apiService(
        {
          url: `/featured-partners`,
          method: "POST",
          data,
        },
        { baseURL: webURL }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getApikey: async (data) => {
    try {
      const response = await apiService(
        {
          url: `/generatekey`,
          method: "POST",
          data,
          headers: {
            app_identifier: process.env.REACT_APP_APP_IDENTIFIER,
            "Content-Type": "application/x-www-form-urlencoded ",
          },
        },
        { baseURL: gateWayURL }
      );

      return response;
    } catch (error) {
      throw error;
    }
  },
};
