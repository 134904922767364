import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectLangState } from "../../redux/configurationSlice";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { HomeAPI } from "../../apis/homeApi";

const FeaturedBrands = ({
  title,
  description,
  showMore = false,
  brands = [],
}) => {
  const { lang } = useSelector(selectLangState);
  const location = useLocation();
  const { t } = useTranslation();
  const { data: featuredMerchants } = useQuery({
    queryKey: [
      "featuredMerchants",
      {
        ...(location?.pathname?.startsWith("/about")
          ? {
              partner_type: "ENTITY",
            }
          : {
              partner_type: "MERCHANT",
            }),
      },
    ],
    queryFn: ({ queryKey }) => HomeAPI.getMerchants({ ...queryKey[1] }),
  });
  return (
    <section className="offer-section fBrands">
      {(location?.pathname?.startsWith("/about")
        ? featuredMerchants?.entity_partners_logo_urls?.length > 0
        : featuredMerchants?.featured_partners_logo_urls?.length > 0) && (
        <Container>
          <Row className="head g-0">
            <Col xs={7}>
              <h2 className="fw-700 font-bukra text-black title-lg">{title}</h2>
            </Col>
            {showMore && (
              <Col xs={5} className="d-flex justify-content-end">
                <div>
                  <Link
                    to="/partner-facilities"
                    className="primary-color text-decoration-none fw-500 link btn btn-outline"
                  >
                    {t("Show more")}
                  </Link>
                </div>
              </Col>
            )}
            <Col xs={12} className="d-none d-md-block">
              <small className="fw-300 text-black text-info">
                {description} (
                {location?.pathname?.startsWith("/about")
                  ? featuredMerchants?.entity_partners_logo_urls?.length
                  : featuredMerchants?.featured_partners_logo_urls?.length}
                )
              </small>
            </Col>
          </Row>
          <Row xs={1} className="g-0">
            <Col>
              <Swiper
                dir={lang?.startsWith("ar") ? "rtl" : "ltr"}
                key={lang?.startsWith("ar") ? "rtl" : "ltr"}
                navigation={{
                  prevEl: ".swiper-brand-prev",
                  nextEl: ".swiper-brand-next",
                }}
                modules={[Navigation]}
                className="brand-swiper"
                breakpoints={{
                  320: {
                    slidesPerView: 3,
                    spaceBetween: 12,
                  },
                  768: {
                    slidesPerView: 5,
                    spaceBetween: 16,
                  },
                  1200: {
                    slidesPerView: 6,
                    spaceBetween: 20,
                  },
                }}
              >
                {location?.pathname?.startsWith("/about")
                  ? featuredMerchants?.entity_partners_logo_urls?.map(
                      (logo, key) => (
                        <SwiperSlide key={key}>
                          <Image
                            src={logo}
                            alt="merchant logo"
                            fluid
                            className="w-100"
                          />
                        </SwiperSlide>
                      )
                    )
                  : featuredMerchants?.featured_partners_logo_urls?.map(
                      (logo, key) => (
                        <SwiperSlide key={key}>
                          <Image
                            src={logo}
                            alt="merchant logo"
                            fluid
                            className="w-100"
                          />
                        </SwiperSlide>
                      )
                    )}
                <div className="swiper-button-prev swiper-brand-prev"></div>
                <div className="swiper-button-next swiper-brand-next"></div>
              </Swiper>
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
};

export default FeaturedBrands;
