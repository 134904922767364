import { useEffect } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

const CardCategory = ({
  className,
  to,
  state = {},
  imageSrc,
  alt,
  title,
  color,
}) => {
  return (
    <Card
      className={`category ${className}`}
      style={{ borderBottomColor: color }}
    >
      <Link to={to} state={state}>
        <Card.Body>
          <div className="imgHolder d-inline-block">
            <Card.Img
              variant="top"
              src={imageSrc}
              alt={alt}
              className="d-block"
            />
          </div>
          <Card.Title
            className="fw-500 title d-flex align-items-center justify-content-center"
            style={{ color: color }}
          >
            {title}
          </Card.Title>
        </Card.Body>
      </Link>
    </Card>
  );
};

export default CardCategory;
