import MainHero from "../Components/Home/MainHero";
import MainAbout from "../Components/Home/MainAbout";
import MainInfo from "../Components/Home/MainInfo";
import HomeNews from "../Components/Home/HomeNews";
import FaqContent from "../Components/Home/FaqContent";
import AppDownload from "../Components/Global/AppDownload";
import OnboardingJourney from "../Components/Modal/OnboardingJourney";
import { useSelector } from "react-redux";
import { selectOnboarding } from "../redux/authSlice";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { HomeAPI } from "../apis/homeApi";
import { selectLangState } from "../redux/configurationSlice";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { useState } from "react";
import { toast } from "react-toastify";
import { MoengageService } from "../services/moengage";
import PageLoader from "../Components/loaders/pageLoader";

const HomeGuest = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const isOnboarding = useSelector(selectOnboarding);
  const client = useQueryClient();
  const { lang } = useSelector(selectLangState);
  const { t } = useTranslation();
  const { data, refetch, isLoading } = useQuery({
    queryKey: [
      "home",
      {
        company: "ESD",
        location_id: 0,
        language: lang?.startsWith("ar") ? "ar" : "en",
        platform: "website",
      },
    ],
    queryFn: ({ queryKey }) => HomeAPI.getHomeSections({ ...queryKey[1] }),
  });

  const onSuccess = () => {
    refetch();
  };

  const onError = (error) => {
    toast.error(error?.message);
  };

  const { mutate: addVisits, error } = useMutation({
    mutationFn: (data) => HomeAPI.visit(data),
    onSuccess,
    onError,
  });

  const news =
    data?.sections?.find(
      (section) => section?.section_identifier === "news_section"
    )?.section_items ?? [];

  const newVisitor = async () => {
    try {
      const token = await executeRecaptcha("visitor");
      addVisits({ captcha_token: token });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    executeRecaptcha && newVisitor();
  }, [executeRecaptcha]);

  useEffect(() => {
    MoengageService.visitHomePage();
    MoengageService.homeScreenView({
      app_language: "",
      user_type: "guest",
      "Account Status": "Account Status",
    });
  }, []);
  const isMobile = window.innerWidth <= 767;

  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <MainHero />
      <MainAbout />
      <MainInfo />
      <HomeNews news={news} title={`${t("News")}`} />
      <FaqContent />
      <AppDownload />
      {/* {isOnboarding && <OnboardingJourney />} */}
    </>
  );
};

export default HomeGuest;
