import React from 'react'
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectLangState } from "../redux/configurationSlice";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";

const NotFound = () => {
  const { lang } = useSelector(selectLangState);
  const { t } = useTranslation();
  return (
    <div className='d-flex align-items-center justify-content-center full-page notFound'>
      <div className='text-center'>
        <div className='imgHolder d-inline-block mb-3 mb-md-4'>
          <Image
            src="/images/page-not-found.svg"
            alt="Page Not Found"
            img-fluid
            className="d-block w-100"
          />
        </div>
        <h2 className='font-bukra fw-300 title-lg mb-4'>{t("Page Not Found")}</h2>
        <Link
            to="/"
            className="btn btn-primary fw-400 font-dubai w-75"
          >
            {t("Back to Homepage")}
          </Link>
      </div>
    </div>
  )
}

export default NotFound