import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { privateNavigations, publicNavigations } from "../../constants";

const Layout = ({
  navigations,
  loggedIn,
  isAuthRoute = false,
  children,
  isHeaderFooter,
}) => {
  return (
    <>
      {isHeaderFooter && (
        <Header navigations={navigations} loggedIn={loggedIn} />
      )}
      {children}
      {isHeaderFooter && !isAuthRoute && <Footer loggedIn={loggedIn} />}
    </>
  );
};

export const withLayout = (
  Component,
  isAuthenticated,
  isAuthRoute,
  isHeaderFooter = true
) => {
  const navigations = isAuthenticated ? privateNavigations : publicNavigations;
  return (
    <main className="pageWrap">
      <Layout
        navigations={navigations}
        loggedIn={isAuthenticated}
        isAuthRoute={isAuthRoute}
        isHeaderFooter={isHeaderFooter}
      >
        <Component />
      </Layout>
    </main>
  );
};
