import React, { useState } from "react";
import OtpVerification from "../../Components/auth/otpVerification";
import ForgotPassword from "../../Components/auth/forgetPassword";

const ForgetFlow = () => {
  const [current, setCurrent] = useState(0);
  const [state, setState] = useState({
    mobile_no: null,
    email: null,
  });

  return (
    <>
      {current === 0 && (
        <ForgotPassword
          setCurrent={setCurrent}
          setState={setState}
          state={state}
        />
      )}
      {current === 1 && (
        <OtpVerification
          setCurrent={setCurrent}
          state={state}
          setState={setState}
          type="FORGET_PASSWORD"
        />
      )}
    </>
  );
};

export default ForgetFlow;
