import Image from "react-bootstrap/Image";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const EmptyState = ({ imageUrl, text, btnText, className, url = "#" }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`emptyState d-flex align-items-center justify-content-center ${className}`}
    >
      <div className="textHolder text-center">
        <div className="imgHolder d-inline-block">
          <Image
            src={imageUrl}
            alt={t("Favourite Icon")}
            fluid
            className="d-block"
          />
        </div>
        <p>{text}</p>
        {btnText && (
          <Button variant="primary" className="w-100" as="a" href={url}>
            {btnText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EmptyState;
